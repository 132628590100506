import PropTypes from "prop-types"
import React, { createContext, useContext, useMemo, useState } from "react"

export const ConfigurationContext = createContext()

export const ConfigurationProvider = ({ children, value = { filter: [] } }) => {
  const [filter, setFilter] = useState(value?.filter || [])
  const [isFilterSet, setIsFilterSet] = useState(false)

  const ConfigurationValue = useMemo(() => ({
    filter,
    isFilterSet,
    setFilter,
    setIsFilterSet
  }), [filter, isFilterSet])

  return (
    <ConfigurationContext.Provider value={ ConfigurationValue }>
      { children }
    </ConfigurationContext.Provider>
  )
}

ConfigurationProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.object
}

export const useConfigContext = () => {
  return useContext(ConfigurationContext)
}
