import { decodeURIString, encodeURIString, generateUUID } from "../../../utils/Common/common"
import { TABLE_ACTION } from "../../../utils/Constants/AssetsConfig"
import { ConfigAuthElement } from "../../../utils/Constants/Auth/configuration"
import { CONFIG_SW_STATUS, SEARCH_DEFAULT_MIN_CHARACTERS } from "../../../utils/GlobalConstants"

export const transformDeviceConfigs = (data, searchInput) => {
  const deviceConfigs = data?.data?.configurations ? data?.data?.configurations : []
  const filteredConfig = getSortedData(deviceConfigs)

  const configurations = []
  for (const deviceConfig of filteredConfig) {
    if (!deviceConfig?.deleted) {
      configurations.push({
        assetSerialNumber: deviceConfig?.assetSerialNumber,
        configFile: deviceConfig.fileName,
        configType: deviceConfig.configType,
        configurationDate: deviceConfig.configurationDate,
        description: decodeURIString(deviceConfig.description),
        id: deviceConfig?.id,
        isDeleted: deviceConfig?.deleted,
        isDeploymentInProgress: deviceConfig.isDeploymentInProgress,
        latestNote: decodeURIString(deviceConfig.latestNote),
        locked: deviceConfig?.locked,
        model: deviceConfig?.model,
        promoted: deviceConfig?.promoted,
        user: deviceConfig.assetUser
      })
    }
  }
  return {
    ...getConfigurationActionAccessKeys(configurations),
    configurations: searchInput && searchInput?.length < SEARCH_DEFAULT_MIN_CHARACTERS ? [] : configurations
  }
}

export const recentActivitiesData = (data) =>
  data?.filter((activity) => activity?.status !== CONFIG_SW_STATUS.PAUSED && activity)

const getConfigurationActionAccessKeys = (configs) => {
  const accessKeys = [ConfigAuthElement.DeployAssetConfig, ConfigAuthElement.DeleteAssetConfig]
  const hasSomeDemotedFiles = configs?.some((item) => !item?.promoted)
  const hasSomeLockedFiles = configs?.some((item) => item?.locked)
  const hasSomePromotedFiles = configs?.some((item) => item?.promoted)
  const hasSomeUnLockedFiles = configs?.some((item) => !item?.locked)

  hasSomeLockedFiles && accessKeys.push(ConfigAuthElement.UnlockAssetConfig)
  hasSomeUnLockedFiles && accessKeys.push(ConfigAuthElement.LockAssetConfig)
  hasSomePromotedFiles && accessKeys.push(ConfigAuthElement.DemoteAssetConfig)
  hasSomeDemotedFiles && accessKeys.push(ConfigAuthElement.PromoteAssetConfig)

  return {
    accessKeys,
    hasSomeDemotedFiles,
    hasSomeLockedFiles,
    hasSomePromotedFiles,
    hasSomeUnLockedFiles
  }
}

const getSortedData = (data) => {
  return data.sort((a, b) => new Date(b.lastModifiedTS) - new Date(a.lastModifiedTS))
}

export const getRequestConfigFilePayload = (serialNumber) => {
  return {
    variables: {
      correlationId: generateUUID(),
      serialNumber
    }
  }
}

export const getConfigTabDeploymentPayload = (serialNumbers, fileId, fromCentral, isRetry, jobId) => {
  return {
    variables: {
      correlationId: generateUUID(),
      fileId,
      fromCentral: fromCentral ?? false,
      isRetry: Boolean(isRetry),
      parentJobId: jobId ?? null,
      serialNumber: serialNumbers
    }
  }
}

export const getConfigTabCancelPayload = (jobId, serialNumbers) => {
  return {
    jobId,
    variables: {
      correlationId: generateUUID(),
      serialNumbers
    }
  }
}

export const getLockUnlockFilePayload = (assetSerialNumber, configFileId, locked) => {
  return {
    assetSerialNumber,
    configFileId,
    variables: { locked }
  }
}

export const getPromoteDemoteFilePayload = (assetSerialNumber, configFileId, promoted, note) => {
  return {
    assetSerialNumber,
    configFileId,
    variables: { description: encodeURIString(note), promoted }
  }
}

export const getDeleteFilePayload = (assetSerialNumber, configFileId, deleted) => {
  return {
    assetSerialNumber,
    configFileId,
    variables: { deleted }
  }
}

export const openPromptModalOnAction = (actionType, fileDetails, stateSetter, t) => {
  switch (actionType) {
  // case TABLE_ACTION.PROMOTE:
  //   stateSetter({
  //     actionType,
  //     fileDetails,
  //     heading: t("configurations.popup.demote.heading"),
  //     isOpen: true,
  //     message: t("configurations.popup.demote.message", {
  //       fileName: fileDetails.configFile
  //     })
  //   })
  //   break
  case TABLE_ACTION.DELETE:
    stateSetter({
      actionType,
      fileDetails,
      heading: t("configurations.popup.delete.heading"),
      isOpen: true,
      message: t("configurations.popup.delete.message", {
        // dateTime: fileDetails.configurationDate,
        fileName: fileDetails.configFile
      })
    })
    break
  case TABLE_ACTION.LOCK:
    stateSetter({
      actionType,
      fileDetails,
      heading: t("configurations.popup.unlockFile.heading"),
      isOpen: true,
      message: t("configurations.popup.unlockFile.message", {
        fileName: fileDetails.configFile
      })
    })
    break

  default:
    break
  }
}

export const intialState = {
  fileDetails: "",
  isOpen: false,
  message: "",
  name: "",
  type: ""
}
