import { FleetAuthElement } from "../../utils/Constants/Auth/fleet"
import { EMPTY_CELL_DEFAULT_VALUE } from "../../utils/Constants/DataTable"
import { GROUP_BY_CHANGE_METHOD } from "../../utils/Constants/SiteView"
import { softwareStatus } from "../../utils/helper"
import { mockAvailableUpdates } from "../SiteView/SiteUtils"

const getAssetsStatistics = (assets) => {
  return {
    countValue: assets?.total ?? 0,
    distribution: [assets?.available ?? 0, assets?.inUse ?? 0, assets?.offline ?? 0],
    mutedAssetCount: assets?.disabled ?? 0,
    showMutedCount: true,
    type: "assets"
  }
}

const getFaultsStatistics = (faults) => {
  return {
    countValue: faults?.total ?? 0,
    distribution: [faults?.critical ?? 0, faults?.moderate ?? 0, faults?.low ?? 0],
    mutedAssetCount: faults?.disabled ?? 0,
    showMutedCount: true,
    type: "alerts"
  }
}

const getConfigsStatistics = (configs) => {
  return {
    countValue: configs?.total ?? 0,
    distribution: [configs?.inInstall || 0, configs?.inDownload ?? 0, configs?.failed ?? 0],
    type: "configs"
  }
}

const getUpdatesStatistics = (updates) => {
  return {
    countValue: updates?.total ?? 0,
    distribution: [updates?.inProgress || updates?.inprogress || 0, updates?.pending ?? 0, updates?.failed ?? 0],
    type: "updates"
  }
}

const getDistributionData = (data) => {
  return [
    getAssetsStatistics(data?.assets),
    getFaultsStatistics(data?.faults),
    getConfigsStatistics(data?.configs),
    getUpdatesStatistics(data?.updates)
  ]
}

export const transformFleetStatistics = (data) => {
  const subFleets = data || []
  return {
    distributionData: getDistributionData(subFleets)
  }
}

export const transformFleetInformation = (data) => {
  const assetsNotInSubFleetStatistics = data?.assetsNotInSubFleetStatistics?.statistics
  const subFleets = data?.subFleets || []
  const assets = data?.devices || []

  for (const content of subFleets) {
    content["distributionData"] = getDistributionData(content?.statistics)
  }

  const assetsNotInSubFleet = {
    assets: transformAssets(assets),
    distributionData: getDistributionData(assetsNotInSubFleetStatistics),
    name: "Assets not in a sub-fleet"
  }

  return {
    assetsNotInSubFleet: assetsNotInSubFleet,
    currentFleetDetails: {
      id: data?.site?.fleetId,
      name: data?.site?.fleetName
    },
    subFleets
  }
}

export const transformAssets = (data = []) => {
  const assets = []

  for (const [index, asset] of data.entries()) {
    assets.push({
      assetNote: asset.assetNote ?? "--",
      availableUpdates: mockAvailableUpdates(asset.softwareVersion, index),
      ...asset,
      configStatus: asset?.configStatus?.toLowerCase(),
      connection: asset.connectionType,
      deviceId: asset.serialNumber,
      id: asset.id,
      ipAddress: asset.ipAddress,
      lastLocation: "Minnesota",
      mute: asset?.muted,
      name: asset?.friendlyName,
      scheduledTime: index === 4 ? new Date() : null,
      severity: asset.faultSeverity,
      softwareVersion: asset?.softwareVersion,
      status: asset?.connectivityStatus || "Offline",
      subFleet: asset.fleetName || EMPTY_CELL_DEFAULT_VALUE,
      swStatus: softwareStatus(asset?.swStatus?.toLowerCase(), asset?.swInProgressStatus?.toLowerCase()),
      uuid: asset.id
    })
  }
  return assets
}

export const transformFilterData = () => {
  return [
    {
      defaultValue: [],
      key: "modality",
      label: "Modality",
      multiple: true,
      options: [],
      required: true
    },
    {
      defaultValue: [],
      key: "model",
      label: "Model",
      multiple: true,
      options: []
    },
    {
      defaultValue: [],
      key: "status",
      label: "Status",
      multiple: true,
      options: [
        {
          id: 1,
          label: "Available"
        },
        {
          id: 2,
          label: "In use"
        },
        {
          id: 3,
          label: "Offline"
        }
      ]
    },
    {
      defaultValue: [],
      key: "severity",
      label: "Fault severity",
      multiple: true,
      options: [
        {
          id: 1,
          label: "Critical"
        },
        {
          id: 2,
          label: "Moderate"
        },
        {
          id: 3,
          label: "Low"
        }
      ]
    }
  ]
}

export const getCreateSubFleetPayload = (fleetName, siteId, parentFleetId) => {
  return {
    variables: {
      fleetName,
      parentFleetId,
      siteId
    }
  }
}

export const getAddRemoveAssetsPayload = (assets, fleetId, siteId) => {
  return {
    fleetId: fleetId,
    siteId: siteId,
    variables: { serialNumbers: assets }
  }
}

export const getRemoveAssetsPayload = (assets, fleetId, siteId) => {
  return {
    fleetId: fleetId,
    siteId: siteId,
    variables: (assets ?? []).join()
  }
}

export const getUpdateSubFleetPayload = (fleetId, siteId, fleetName) => {
  return {
    fleetId,
    variables: {
      fleetName,
      parentFleetId: siteId
    }
  }
}

export const GROUP_BY_TYPES = {
  1: {
    idKey: "id",
    responseKey: "subFleets",
    serviceName: "",
    titleKey: "name"
  },
  2: {
    responseKey: "modalities",
    serviceName: "modalities",
    titleKey: "modality"
  },
  3: {
    responseKey: "models",
    serviceName: "models",
    titleKey: "model"
  },
  6: {
    responseKey: "connections",
    serviceName: "connections",
    titleKey: "connection"
  },
  7: {
    responseKey: "assets",
    serviceName: "assets",
    titleKey: "name"
  }
}

export const FLEET_VIEW_ACTION_TYPES = {
  CLOSE_CONFIRM_MOVE_FLEET_POPUP: "closeConfirmMoveFleetPopup",
  CLOSE_MOVE_FLEET_POPUP: "closeMoveFleetPopup",
  CONFIRM_POPUP: "openConfirmMoveFleetPopup",
  CREATE_UPDATE_POPUP: "createUpdatePopup",
  DRAG_DETAILS: "dragDetails",
  ENABLE_GROUP_BY_DROPDOWN: "enableGroupingOption",
  FILTER_INPUT: "filterInput",
  FILTER_POPUP_STATE: "filterPopupState",
  FILTER_RESULT: "filterResult",
  OPEN_MOVE_FLEET_POPUP: "openMoveFleetPopup",
  SEARCH_ERROR: "searchError",
  SEARCH_INPUT: "searchInput",
  SEARCH_RESULTS: "searchResults",
  SET_GROUP_BY_CHANGE_METHOD: "setGroupByChangeMethod",
  SET_GROUP_BY_DROPDOWN: "setGroupBySelectedValue",
  SET_NEW_FLEET_SELECTION: "showNewFleetSection",
  SET_SELECTED_ASSETS: "setSelectedAssets",
  TOGGLE_ACCORDION_EXPAND: "toggleAccordionExpand",
  TOGGLE_VIEW: "toggleView"
}

export const FLEET_VIEW_STATE = {
  confirmPopup: false,
  createUpdatePopup: {
    isOpen: false,
    name: "",
    type: { action: "", assets: [], id: "", type: "" }
  },
  draggingDetails: {
    assets: [],
    fleet: {}
  },
  enableGroupingOption: false,
  groupByChangeMethod: GROUP_BY_CHANGE_METHOD.DEFAULT,
  groupByMappingObject: GROUP_BY_TYPES[1],
  groupBySelectedValue: 1,
  isAssetsNotInFleetExpanded: false,
  isCardView: false,
  isFilterOpen: false,
  moveFleet: false,
  searchError: false,
  searchInput: null,
  searchResults: null,
  selectedAssets: [],
  selectedFilters: [],
  showNewFleetSection: false
}

export const fleetViewReducer = (newState, action) => {
  let prepareNewState = {}
  switch (action.type) {
  case FLEET_VIEW_ACTION_TYPES.OPEN_MOVE_FLEET_POPUP:
    return {
      ...newState,
      ...{
        moveFleet: action.payload
      }
    }
  case FLEET_VIEW_ACTION_TYPES.CONFIRM_POPUP:
    return {
      ...newState,
      ...{
        confirmPopup: action.payload
      }
    }
  case FLEET_VIEW_ACTION_TYPES.FILTER_INPUT:
    return {
      ...newState,
      ...{
        selectedFilters: action.payload
      }
    }

  case FLEET_VIEW_ACTION_TYPES.FILTER_RESULT:
    return {
      ...newState,
      ...{
        filterResult: action.payload
      }
    }
  case FLEET_VIEW_ACTION_TYPES.CREATE_UPDATE_POPUP:
    return {
      ...newState,
      ...{
        createUpdatePopup: action.payload
      }
    }
  case FLEET_VIEW_ACTION_TYPES.TOGGLE_VIEW:
    return {
      ...newState,
      ...{
        isCardView: !newState.isCardView
      }
    }
  case FLEET_VIEW_ACTION_TYPES.SET_SELECTED_ASSETS:
    return {
      ...newState,
      ...{
        selectedAssets: action.payload
      }
    }
  case FLEET_VIEW_ACTION_TYPES.SET_GROUP_BY_DROPDOWN:
    return {
      ...newState,
      ...{
        groupByMappingObject: GROUP_BY_TYPES[action.payload],
        groupBySelectedValue: action.payload
      }
    }
  case FLEET_VIEW_ACTION_TYPES.SET_GROUP_BY_CHANGE_METHOD:
    return {
      ...newState,
      ...{
        groupByChangeMethod: action.payload
      }
    }
  case FLEET_VIEW_ACTION_TYPES.SEARCH_ERROR:
    return {
      ...newState,
      ...{
        searchError: action.payload.hasError,
        searchInput: action.payload.value,
        searchResults: null
      }
    }
  case FLEET_VIEW_ACTION_TYPES.SEARCH_INPUT:
    prepareNewState = searchInputReducerFn(action, newState)
    return {
      ...newState,
      ...prepareNewState
    }
  case FLEET_VIEW_ACTION_TYPES.SEARCH_RESULTS:
    return {
      ...newState,
      ...{
        searchResults: action.payload
      }
    }
  case FLEET_VIEW_ACTION_TYPES.SET_NEW_FLEET_SELECTION:
    return {
      ...newState,
      ...{
        defaultFleetCardDetails: {
          ...newState.defaultFleetCardDetails,
          distributionData: newState?.defaultFleetCardDetails?.distributionData?.map((item) => {
            if (item.type === "assets") item.distribution[0] = newState?.selectedAssets?.length
            return item
          })
        },
        showNewFleetSection: action.payload
      }
    }
  case FLEET_VIEW_ACTION_TYPES.DRAG_DETAILS:
    return {
      ...newState,
      ...action.payload
    }
  case FLEET_VIEW_ACTION_TYPES.TOGGLE_ACCORDION_EXPAND:
    return {
      ...newState,
      ...{
        isAssetsNotInFleetExpanded: action.payload
      }
    }

  case FLEET_VIEW_ACTION_TYPES.FILTER_POPUP_STATE:
    return {
      ...newState,
      ...{
        isFilterOpen: action.payload
      }
    }

  case FLEET_VIEW_ACTION_TYPES.ENABLE_GROUP_BY_DROPDOWN:
    return {
      ...newState,
      ...{
        enableGroupingOption: action.payload
      }
    }
    /* istanbul ignore next */
  default:
    return newState
  }
}

const searchInputReducerFn = (action, newState) => {
  let prepareNewState = {
    selectedAssets: []
  }
  if (action.payload.closeAction) {
    prepareNewState.searchError = false
    prepareNewState.searchResults = null
    prepareNewState.searchInput = null
    prepareNewState.closeAction = true
    if (
      newState?.groupByChangeMethod === GROUP_BY_CHANGE_METHOD.SEARCH &&
      (!newState?.selectedFilters || newState?.selectedFilters?.length === 0)
    ) {
      prepareNewState.groupByMappingObject = GROUP_BY_TYPES[1]
      prepareNewState.groupBySelectedValue = 1
    }
    return prepareNewState
  }
  if (action.payload.value?.length >= 3) {
    if (newState?.groupByChangeMethod !== GROUP_BY_CHANGE_METHOD.DROPDOWN) {
      prepareNewState.groupByChangeMethod = GROUP_BY_CHANGE_METHOD.SEARCH
    }
    prepareNewState.groupByMappingObject = GROUP_BY_TYPES[7]
    prepareNewState.groupBySelectedValue = 7
    prepareNewState.searchInput = action.payload.value
    prepareNewState.searchError = false
    prepareNewState.closeAction = false
  } else if (action.payload.value?.length < 3) {
    if (newState?.groupByChangeMethod !== GROUP_BY_CHANGE_METHOD.DROPDOWN) {
      prepareNewState.groupByChangeMethod = GROUP_BY_CHANGE_METHOD.SEARCH
    }
    prepareNewState.groupByMappingObject = GROUP_BY_TYPES[7]
    prepareNewState.groupBySelectedValue = 7
    prepareNewState.searchResults = null
    prepareNewState.searchError = true
  }
  return prepareNewState
}

export const FLEET_ACCESS = {
  moveAsset: FleetAuthElement.MoveAsset
}
