import { MenuItem } from "@mui/material"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"

import { ICON_NAME } from "../../../utils/GlobalConstants"
import Icon from "../../Icon/Icon"
import styles from "../SearchBar.module.scss"

// eslint-disable-next-line react/display-name
const SearchByItems = forwardRef(({
  handleSearchMenuClick,
  isSearchMenuDisabled,
  searchByMenuItems
}, ref) => {
  return (
    <div className={ styles.searchMenuContainer } ref={ ref }>
      { searchByMenuItems.map((item) => (
        <MenuItem
          key={ item.label }
          className="SearchMenuItem"
          onClick={ (e) => handleSearchMenuClick(e, item.key) }
          disabled={ isSearchMenuDisabled }
        >
          <div className={ styles.searchMenuItem }>
            <div className={ styles.searchMenuIconDiv }>
              <Icon icon={ ICON_NAME.search } iconStyles={ styles.menuIcon } />
            </div>
            <span className={ styles.searchMenuText }>{ item.label }</span>
          </div>
        </MenuItem>
      )) }
    </div>
  )
})

SearchByItems.propTypes = {
  handleSearchMenuClick: PropTypes.func,
  isSearchMenuDisabled: PropTypes.bool,
  searchByMenuItems: PropTypes.array
}

export default SearchByItems
