/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"

import HeadSection from "./internals/HeadSection"

import CreateUpdateLabelPopup from "../../../components/CreateUpdateLabelPopup/CreateUpdateLabelPopup"
import Error from "../../../components/Error/Error"
import FilterPopup from "../../../components/FilterPopup/FilterPopup"
import Loading from "../../../components/Loading/Loading"
import { useFleetViewContext } from "../../../contexts/fleetView/FleetView"
import { GROUP_BY_CHANGE_METHOD } from "../../../utils/Constants/SiteView"
import useCheckBoxSelection from "../../../utils/CustomHooks/useCheckBoxSelection"
import {
  COMPARE_VALUE,
  CREATE,
  SEARCH_DEFAULT_MIN_CHARACTERS,
  defaultSubFleetCardDetails
} from "../../../utils/GlobalConstants"
import { StorageKeys, clearSession, getSession } from "../../../utils/SessionHelper/session"
import { transformAssets } from "../FleetUtils"
import style from "../FleetView.module.scss"
import GroupByView from "../GroupByView/GroupByView"

// eslint-disable-next-line react/display-name
const SubFleetsSummary = forwardRef((props, ref) => {
  const {
    fleetViewState,
    setGroupBySelectedValue,
    updateFilters,
    handleSearchAssets,
    setGroupByChangeMethod,
    setSearchError,
    ṣearchInputRef,
    handleCreateUpdatePopup,
    addDeviceMutationResults,
    assetsNotInSubfleetContainerRef,
    setSelectedAssets,
    groupByAssetsRef,
    handleFilterState
  } = useFleetViewContext()

  const { t: common } = useTranslation(["common"])
  const [filterAPIError, setFilterAPIError] = useState(false)

  useImperativeHandle(ref, () => ({
    resetSelection() {
      assetsNotInSubfleetContainerRef?.current?.resetSelection(false)
      groupByAssetsRef?.current?.resetSelection(false)
      resetSelection()
    }
  }))

  const updateFilterItem = (value) => {
    ṣearchInputRef?.current?.reset()
    const modalities = value.filter((item) => item.type === COMPARE_VALUE.modality).map((item) => item.label)
    const filterItem = value.filter((item) =>
      item.type === COMPARE_VALUE.model ? modalities.includes(item.parent.trim()) : true
    )

    const isModalityAvailable = value.filter((option) => option.type === COMPARE_VALUE.modality)
    const setFilterValue = isModalityAvailable.length ? filterItem : []

    updateFilters(setFilterValue)
    resetSelection()
    assetsNotInSubfleetContainerRef?.current?.resetSelection()
  }

  const handleNewFleetSubmitted = () => {
    resetSelection()
    assetsNotInSubfleetContainerRef?.current?.resetSelection()
    resetSelection()
    if (fleetViewState?.groupBySelectedValue === 7) {
      setGroupBySelectedValue(1)
      setGroupByChangeMethod(GROUP_BY_CHANGE_METHOD.DROPDOWN)
    }
    clearSession(StorageKeys.FLEET_CREATE_FLEET_REF)
  }

  const handleSetIsEdit = (data) => {
    const fleetGroupByCreateFleetRef = getSession(StorageKeys.FLEET_CREATE_FLEET_REF)
    if (
      fleetGroupByCreateFleetRef &&
      fleetViewState?.createUpdatePopup?.type?.action === CREATE &&
      data?.isOpen === false
    ) {
      assetsNotInSubfleetContainerRef?.current?.resetSelection()
      resetSelection()
    }
    clearSession(StorageKeys.FLEET_CREATE_FLEET_REF)
    handleCreateUpdatePopup(data)
  }

  const handleAddSubFleet = (value, groupBySelectedAssets) => {
    handleCreateUpdatePopup({
      isOpen: value,
      name: "",
      type: {
        action: CREATE,
        assets: groupBySelectedAssets || fleetViewState.selectedAssets,
        id: fleetViewState.fleetId,
        type: "subfleet"
      }
    })
    const groupByAssetsLength = groupBySelectedAssets?.length > 0 ? groupBySelectedAssets?.length : 0
    const assetsLength =
      fleetViewState.selectedAssets?.length > 0 ? fleetViewState.selectedAssets?.length : groupByAssetsLength
    defaultSubFleetCardDetails?.distributionData?.forEach((item) => {
      if (item.type === "assets") item.distribution[0] = assetsLength
    })
  }

  const assetsList = fleetViewState?.subFleets

  const tableData = useCallback(transformAssets(assetsList), [assetsList])

  const { handleSelectRow, resetSelection, handleSelectAll } = useCheckBoxSelection({
    data: tableData,
    onReset: () => setSelectedAssets([]),
    onSelectAllCallback: (data) => setSelectedAssets(data),
    onSelectRowCallback: (selectedRows) => setSelectedAssets(selectedRows)
  })

  const setIsSearch = (value, closeAction, hasError) => {
    if ((closeAction && !hasError) || (!closeAction && value.length >= SEARCH_DEFAULT_MIN_CHARACTERS)) {
      if (fleetViewState?.searchInput !== value) {
        resetSelection()
        assetsNotInSubfleetContainerRef?.current?.resetSelection()
        groupByAssetsRef?.current?.resetSelection()
        handleSearchAssets(value, closeAction)
        setSearchError(value, false)
      }
    } else {
      setSearchError(value, true)
    }
  }

  const handleChangeGroupByOption = (value) => {
    resetSelection()
    assetsNotInSubfleetContainerRef?.current?.resetSelection()
    setGroupBySelectedValue(value)
  }

  if (addDeviceMutationResults.loading) {
    return <Loading />
  }

  if (filterAPIError) return <Error error={ { message: common("errorInFilterOptionsApi") } } />

  return (
    <>
      <HeadSection
        setOpenFilter={ handleFilterState }
        setSelectedFilter={ updateFilterItem }
        handleChangeGroupByOption={ handleChangeGroupByOption }
        handleNewFleetClicked={ handleAddSubFleet }
        setIsSearch={ setIsSearch }
        assetsList={ tableData }
        { ...props }
      />
      <GroupByView
        handleSelectRow={ handleSelectRow }
        handleSelectAll={ handleSelectAll }
        handleAddSubFleet={ handleAddSubFleet }
      />
      { fleetViewState?.isFilterOpen && (
        <FilterPopup
          popupStyles={ style.popupStyles }
          data={ fleetViewState?.fleetViewFilterData }
          onCancel={ handleFilterState }
          onSubmit={ updateFilterItem }
          filters={ fleetViewState.selectedFilters }
          customLoadingStyle={ style.contentLoading }
          showToastError={ false }
          setFilterAPIError={ setFilterAPIError }
        />
      ) }
      { fleetViewState.createUpdatePopup.isOpen && (
        <CreateUpdateLabelPopup
          dataId={ fleetViewState?.createUpdatePopup?.type?.id }
          isEditPopupOpen={ fleetViewState.createUpdatePopup }
          setIsEditPopupOpen={ handleSetIsEdit }
          popupStyles={ style.popupStyle }
          handleNewFleetSubmitted={ handleNewFleetSubmitted }
          ignoreRefetch={ fleetViewState?.groupBySelectedValue === 7 }
        />
      ) }
    </>
  )
})

export default SubFleetsSummary
