import { PropTypes } from "prop-types"
import React, { useId } from "react"

import styles from "./SucessActivityData.module.scss"

import { classNames } from "../../utils/styles/helper"
import Bar from "../Bar/Bar"

const BAR_CONFIG = ["#97C69B", "#31323514"]

const SucessActivityData = ({
  distribution
}) => {
  const id = useId()

  const percentage = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue
  }

  const setProgressWidth = (count) => {
    const a = percentage(count[0], count[1])
    const b = 100 - a
    return [a, b]
  }

  return (
    <div className={ classNames(styles.activityDataWrapper) }>
      <div className={ classNames(styles.barsWrapper) }>
        { distribution.map((progress, i) => {
          const progressWidth = setProgressWidth(distribution)[i]
          return progressWidth ? (
            <Bar
              key={ id + progress }
              color={ BAR_CONFIG[i] }
              barStyles={ {
                borderEndEndRadius:  "12px",
                borderRadius: i == 0 ? "12px" : 0,
                borderStartEndRadius: "12px",
                marginRight: 0,
                width: `${progressWidth}%`
              } }
            />
          ) : null
        }) }
      </div>
      <span className={ styles.successCount }>{ `${distribution[0]}/${distribution[1]} success` }</span>
    </div>
  )
}

SucessActivityData.propTypes = {
  distribution: PropTypes.arrayOf(PropTypes.number)
}

export default SucessActivityData
