const getInConfigStatistics = (configs) => {
  return {
    countValue: configs?.total,
    distribution: [configs?.inInstall, configs?.inDownload, configs?.failed],
    type: "assets_in_config"
  }
}

const getConfigActivitiesStatistics = (configs) => {
  return {
    countValue: configs?.total,
    distribution: [configs?.inInstall, configs?.inDownload, configs?.failed],
    type: "config_activities"
  }
}

const getModalityChartStatistics = (data) => {
  const chartData = []
  Object.entries(data).forEach(([key, value]) => {
    chartData.push({
      label: key,
      value: value
    })
  })
  return [
    {
      title: "Successful activities by modality",
      type: "sucessModality",
      value: chartData
    }
  ]
}

const getDistributionData = (assetsInConfig, configActivities) => {
  return [
    getInConfigStatistics(assetsInConfig),
    getConfigActivitiesStatistics(configActivities)
  ]
}

export const transformStatistics = (data) => {
  const { assetsInConfig, configActivities, byModality } = data ?? {}
  return {
    distributionData: getDistributionData(assetsInConfig, configActivities),
    modalityChartData: getModalityChartStatistics(byModality ?? {})
  }
}

export const getModalityandModel = (data) => 
  data?.filter(item => item?.type === "modality" || item?.type === "model")

export const configFilterData = [
  {
    defaultValue: "",
    key: "modality",
    label: "Modality",
    multiple: false,
    options: []
  },
  {
    defaultValue: [],
    key: "model",
    label: "Model",
    multiple: true,
    options: []
  }
]
