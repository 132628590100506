/* eslint-disable react/jsx-indent */
import { Button } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { useIsFetching } from "react-query"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import SingleSelectDropdown from "../../../../components/Dropdown/SingleSelectDropdown"
import Icon from "../../../../components/Icon/Icon"
import SearchBar from "../../../../components/SearchBar/SearchBar"
import SelectedFilters from "../../../../components/SelectedFilterItem/SelectedFilters"
import { useSiteViewContext } from "../../../../contexts/siteView/SiteView"
import { FleetAuthElement } from "../../../../utils/Constants/Auth/fleet"
import { SEARCH_INSTANCES } from "../../../../utils/Constants/Search"
import { GROUP_BY_CHANGE_METHOD } from "../../../../utils/Constants/SiteView"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import { CREATE, DROPDOWN_OPTIONS, GROUP_BY, ICON_NAME } from "../../../../utils/GlobalConstants"
import { SITE_ACCESS } from "../../SiteUtils"
import style from "../../SiteView.module.scss"

const HeadSection = ({
  assetsList,
  handleMoveFleet,
  searchError,
  searchResult,
  setIsSearch,
  setGroupByChangeMethod,
  fleetCount,
  setOpenFilter,
  filters,
  setSelectedFilter
}) => {
  const {
    siteViewState: state,
    toggleView,
    siteTranslation,
    setGroupBySelectedValue,
    setShowNewFleetSection,
    setIsEdit,
    siteId,
    ṣearchInputRef,
    disableToggle
  } = useSiteViewContext()
  const isFetchingData = useIsFetching({
    queryKey: state?.groupBySiteQueryKey
  })

  const searchMetadata = searchResult?.pagination
  const isMoveButtonOn = useAccess(SITE_ACCESS.moveAsset) && state?.selectedAssets?.length > 0 && fleetCount > 0

  const handleClick = (buttonLabel) => {
    if (buttonLabel === "viewButton") {
      toggleView()
    } else if (buttonLabel === "filterButton") {
      setOpenFilter(true)
    }
  }

  const handleChangeGroupBy = (value) => {
    setShowNewFleetSection(false)
    setGroupBySelectedValue(value)
    setGroupByChangeMethod(value == 1 ? GROUP_BY_CHANGE_METHOD.DEFAULT : GROUP_BY_CHANGE_METHOD.DROPDOWN)
  }

  const searchResultText = () => {
    return searchMetadata?.numberOfElement === 0
      ? siteTranslation("noSearchResult")
      : `${siteTranslation("headSection.assetsSearchedCountText", {
        searchCount: searchMetadata?.totalElements
      })}`
  }

  const handleAddFleetClick = () => {
    setIsEdit({
      isOpen: true,
      name: "",
      type: {
        action: CREATE,
        assets: state.selectedAssets,
        id: siteId,
        type: "fleetName"
      }
    })
  }

  const isGroupByDisabled = searchResult || searchError ? true : false

  return (
    <span className={ style.filtersSection }>
      <span className={ style.pageContentLeft }>
        <div>
          <SingleSelectDropdown
            width={ 192 }
            defaultOption={ state?.groupBySelectedValue }
            value={ state?.groupBySelectedValue }
            disabled={ isGroupByDisabled || Boolean(isFetchingData) }
            onChange={ (value) => handleChangeGroupBy(value) }
            options={ DROPDOWN_OPTIONS.SITE_VIEW_GROUP_BY }
            optionBy={ GROUP_BY }
          />
        </div>
        { Object.keys(filters).length > 0 && (
          <div className={ style.filterBlock }>
            <SelectedFilters
              handleClearAll={ () => setSelectedFilter([]) }
              filters={ filters }
              setSelectedFilterItem={ setSelectedFilter }
              selectedFilterItem={ Object.values(filters) }
              filterDisabled={ false }
              isPageLoading={ Boolean(isFetchingData) }
            />
          </div>
        ) }
      </span>
      <span className={ style.pageContentRight }>
        { searchResult ? (
          <span role={ "contentinfo" } className={ style.assetsCountText }>
            { state?.selectedAssets?.length > 0
              ? `${siteTranslation("headSection.assetsSelectedCountText", {
                searchCount: searchMetadata?.totalElements,
                selectedCount: state?.selectedAssets?.length
              })}`
              : searchResultText() }
          </span>
        ) : (
          state?.selectedAssets?.length > 0 && (
            <span className={ style.selectedCountText }>
              { `${siteTranslation("headSection.selectedCountText", {
                selectedCount: state?.selectedAssets?.length
              })}` }
            </span>
          )
        ) }
        { isMoveButtonOn && (
          <Button
            variant="text"
            color="secondary"
            startIcon={ <Icon icon={ ICON_NAME.moveTo } /> }
            onClick={ () => handleMoveFleet() }
          >
            { siteTranslation("move-to") }
          </Button>
        ) }
        <AuthGuard actionType={ FleetAuthElement.CreateFleet }>
          { (!searchResult || state?.selectedAssets?.length > 0) && !searchError && (
            <Button
              variant="text"
              color="secondary"
              startIcon={ <Icon icon={ ICON_NAME.addFleet } /> }
              onClick={ handleAddFleetClick }
              aria-label="Add fleet"
            >
              { siteTranslation("add-fleet") }
            </Button>
          ) }
        </AuthGuard>
        { state?.groupBySelectedValue === 7 && (
          <button
            disabled={ (assetsList.length <= 0 || state.searchError) && Object.keys(filters).length == 0 }
            className={ style.iconButton }
            onClick={ () => handleClick("filterButton") }
            aria-label="Filter"
          >
            <Icon
              icon={ Object.keys(filters).length > 0 ? ICON_NAME.filterBadge : ICON_NAME.filter }
              iconStyles={ Object.keys(filters).length > 0 && style.iconStyles }
            />
          </button>
        ) }
        <button
          disabled={ disableToggle }
          className={ state?.isCardView ? style.toggleButtonCard : style.toggleButtonList }
          onClick={ () => handleClick("viewButton") }
          data-testid={ "button-viewButton" }
        >
          { <Icon icon={ ICON_NAME.gridView } /> }
        </button>
        <div className={ style.searchField }>
          <SearchBar
            type={ SEARCH_INSTANCES.siteView }
            onHandleSubmit={ setIsSearch }
            ref={ ṣearchInputRef }
            isDisabled={
              (!state?.searchInput ||
              state?.searchInput == null) &&
              Boolean(assetsList?.length <= 0) &&
              state?.groupBySelectedValue === 7
            }
          />
        </div>
      </span>
    </span>
  )
}

HeadSection.propTypes = {
  assetsList: PropTypes.array,
  filters: PropTypes.array,
  fleetCount: PropTypes.number,
  handleMoveFleet: PropTypes.func,
  searchError: PropTypes.any,
  searchResult: PropTypes.array,
  setGroupByChangeMethod: PropTypes.func,
  setIsSearch: PropTypes.func,
  setOpenFilter: PropTypes.func,
  setSelectedFilter: PropTypes.func
}

export default HeadSection
