import PropTypes from "prop-types"
import React from "react"

import ActivityAssetsAccordion from "./ActivityAssetsAccordion"

import ConfigActivityAccordion from "../../../../../components/ConfigActivityAccordion/ConfigActivityAccordion"
import ErrorPanel from "../../../../../components/DataTable/internals/ErrorPanel/ErrorPanel"
import Loading from "../../../../../components/Loading/Loading"
import { TABLE_INSTANCES } from "../../../../../utils/Constants/DataTable"
import styles from "../DeploymentActivities.module.scss"

const ConfigActivitiesAccordionItem = ({
  t,
  activityDetails,
  onToggleAccordion,
  isLoadingAssets,
  isErrorAssets,
  throttleActions,
  handleSelectedAssets,
  assetQueryKey
}) => {
  const fleets = activityDetails?.fleets
  return (
    <ConfigActivityAccordion
      t={ t }
      id={ activityDetails.jobId }
      isExpanded={ activityDetails?.isExpanded }
      activityDetails={ activityDetails }
      customClass={ styles.accordionCustomClass }
      onChange={ onToggleAccordion }
    >
      <div className={ styles.innerAccordionWrapper }>
        { isLoadingAssets && (
          <Loading showSubMessage={ false } />
        ) }
        { !isLoadingAssets && isErrorAssets &&
          <ErrorPanel
            type={ TABLE_INSTANCES.CONFIG_DEPLOYMENT_ACTIVITIES }
            queryKey={ assetQueryKey }
            customClass={ styles.assetErrorPanel }
          />
        }
        { !isLoadingAssets && fleets?.map((fleet, i) => (
          <ActivityAssetsAccordion
            key={ fleet?.assetDetails?.serialNumber + "-" + i }
            isExpanded={ i === 0 }
            fleet={ fleet }
            throttleActions={ throttleActions }
            id={ "" + activityDetails.jobId + i }
            handleSelectedAssets={ handleSelectedAssets }
          />
        )) }
      </div>
    </ConfigActivityAccordion>
  )
}

ConfigActivitiesAccordionItem.propTypes = {
  activityDetails: PropTypes.object,
  assetQueryKey: PropTypes.array,
  handleSelectedAssets: PropTypes.func,
  isErrorAssets: PropTypes.any,
  isLoadingAssets: PropTypes.bool,
  onToggleAccordion: PropTypes.func,
  t: PropTypes.any,
  throttleActions: PropTypes.any
}

export default ConfigActivitiesAccordionItem
