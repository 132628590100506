import { IconButton, InputAdornment } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"

import { ICON_NAME } from "../../../utils/GlobalConstants"
import Icon from "../../Icon/Icon"
import styles from "../SearchBar.module.scss"

// eslint-disable-next-line react/display-name
const SearchAdornment = ({
  showClearButton,
  isDisabled,
  setHandleSubmit
}) => {
  return (
    <InputAdornment position="end">
      { showClearButton ? (
        <IconButton
          onClick={ () => setHandleSubmit("", true) }
          aria-label="Clear search"
          name="clear"
        >
          <Icon
            icon={ ICON_NAME.bgClose }
            iconStyle={ styles.icon }
          />
        </IconButton>
      ) : (
        <IconButton
          disabled
          onClick={ () => setHandleSubmit("", true) }
        >
          <Icon
            icon={ isDisabled ? ICON_NAME.searchDisabled : ICON_NAME.search }
            iconStyle={ styles.icon }
          />
        </IconButton>
      ) }
    </InputAdornment>
  )
}

SearchAdornment.propTypes = {
  isDisabled: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  showClearButton: PropTypes.bool
}

export default SearchAdornment
