import { LogFilesAuthElement } from "./Auth/logs"

import { ICON_NAME } from "../GlobalConstants"


export const CONFIG_ACTIONS = {
  REFRESH: {
    icon: ICON_NAME.refresh,
    key: "refresh"
  },
  REQUEST_CONFIG_FILE: {
    icon: ICON_NAME.requestFile,
    key: "requestLogFile"
  }
}

export const ASSET_LOGS_BUTTONS = [
  {
    accessKey: LogFilesAuthElement.Any,
    disabledIcon: ICON_NAME.refresh,
    icon: CONFIG_ACTIONS.REFRESH.icon,
    id: 1,
    isMute: false,
    name: CONFIG_ACTIONS.REFRESH.key
  },
  {
    accessKey: LogFilesAuthElement.RequestAssetLogs,
    disabledIcon: ICON_NAME.requestFileDisabled,
    icon: CONFIG_ACTIONS.REQUEST_CONFIG_FILE.icon,
    id: 2,
    isMute: true,
    name: CONFIG_ACTIONS.REQUEST_CONFIG_FILE.key
  }
]
