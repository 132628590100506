import { Accordion, AccordionDetails, AccordionSummary, IconButton } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import styles from "./ConfigActivityAccordion.module.scss"

import { DATE_FORMAT } from "../../utils/Constants/TimeZones"
import { ICON_NAME } from "../../utils/GlobalConstants"
import { formatDate } from "../../utils/helper"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon"
import ReadOnlyField from "../ReadOnlyField/ReadOnlyField"
import SucessActivityData from "../SucessActivityData/SucessActivityData"

const ConfigActivityAccordion = ({
  t,
  isExpanded = false,
  activityDetails,
  id,
  children,
  className,
  onChange,
  customClass
}) => {
  const [expanded, setExpanded] = useState(isExpanded)

  const activity = activityDetails?.activity
  const statistics = activityDetails?.statistics

  useEffect(() => {
    setExpanded(isExpanded)
  }, [isExpanded])

  const toggleAccordion = () => {
    onChange?.(!expanded)
    setExpanded((prev) => !prev)
  }

  const getLocalFormatedDate = (date) => {
    return formatDate(date, DATE_FORMAT.dateTime)
  }

  return (
    <Accordion
      expanded={ expanded }
      className={ classNames(
        styles.accordionLayout,
        customClass,
        expanded ? "expanded" : "collapsed", "ConfigDeployActivity"
      ) }
      sx={ { border: "none", marginBottom: "16px", position: "unset" } }
      data-testid={ `mui-accordion-${id}` }
    >
      <AccordionSummary
        onClick={ toggleAccordion }
        className={ classNames(
          expanded ? "expanded" : "collapsed",
          styles.accordionSummary
        ) }
        expandIcon={
          <IconButton
            aria-label={ expanded ? `Expanded-${id}` : `Collapsed-${id}` }
            data-testid={ `expand-button-${id}` }
          >
            <Icon icon={ ICON_NAME.expandMoreIcon } />
          </IconButton>
        }
        data-testid={ `mui-accordion-summary-${id}` }
      >
        <div className={ styles.leftPanel }>
          <ReadOnlyField
            value={ `${activity?.fileName} | ${getLocalFormatedDate(activity?.configurationDate)}` }
            showLabelInTooltip={ false }
            valueStyles={ styles.configValueData }
          />
          <ReadOnlyField
            label={ t("centralConfigurations.deploymentActivities.labels.description") }
            value={ activity?.description }
            showLabelInTooltip={ false }
            labelStyles={ styles.labelData }
            valueStyles={ styles.valueData }
          />
          <ReadOnlyField
            label={ t("centralConfigurations.deploymentActivities.labels.user") }
            value={ activity?.userName }
            showLabelInTooltip={ false }
            labelStyles={ styles.labelData }
            valueStyles={ styles.valueData }
          />
          <ReadOnlyField
            label={ t("centralConfigurations.deploymentActivities.labels.deploymentDate") }
            value={ getLocalFormatedDate(activity?.deploymentDate) }
            showLabelInTooltip={ false }
            labelStyles={ styles.labelData }
            valueStyles={ styles.valueData }
          />
        </div>
        <span className={ styles.rightPanel }>
          <SucessActivityData distribution={ [statistics?.success ?? 0, statistics?.total ?? 0] } />
        </span>
      </AccordionSummary>
      <AccordionDetails
        className={ classNames(styles.accordionDetailsStyles, className) }
        sx={ { padding: 0 } }
        data-testid={ `accordion-details-${id}` }
      >
        { children }
      </AccordionDetails>
    </Accordion>
  )
}

ConfigActivityAccordion.propTypes = {
  activityDetails: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  customClass: PropTypes.any,
  id: PropTypes.string,
  isExpanded: PropTypes.bool,
  onChange: PropTypes.func,
  t: PropTypes.any
}

export default ConfigActivityAccordion
