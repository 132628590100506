import i18next from "i18next"
import PropTypes from "prop-types"
import React from "react"
import { useParams } from "react-router-dom"

import styles from "./ConfirmMoveSubFleet.module.scss"

import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup"
import {
  getAddRemoveAssetsPayload,
  getDeleteFleetPayload,
  getRemoveAssetsPayload
} from "../../pages/SiteView/SiteUtils"
import {
  fleetMutationSVC,
  fleetSVCKeys
} from "../../services/reactQueries/fleetsvc"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import {
  CANCEL,
  ICON_NAME,
  OK,
  ROUTE_CONFIG
} from "../../utils/GlobalConstants"
import Icon from "../Icon/Icon"

const ConfirmMoveSubFleet = ({
  isOpen,
  onSubmit,
  popupStyles,
  heading,
  onCancel,
  selectedDevice,
  selectedFleet,
  warning,
  message,
  type,
  deleteFleetDetails
}) => {
  const queryParams = useParams()
  const fleetId = queryParams[ROUTE_CONFIG.FLEET.param]

  const createDeviceList = (device) => device.map(({ serialNumber }) => serialNumber)

  const { requestMutation: addDevices } = useMutationWithHandlers({
    onCompletedCallback: () => onSubmit({ isOpen: false }),
    onErrorCallback: () => onSubmit({ isOpen: false }),
    queryFn: fleetMutationSVC.addAssets,
    refetchQueries: [
      { id: fleetId, key: fleetSVCKeys.GET_DESCRETE_ASSETS_BY_FLEET_ID },
      { id: fleetId, key: fleetSVCKeys.FLEET_VIEW_GROUP_BY },
      { id: fleetId, key: fleetSVCKeys.GET_STATISTICS_BY_FLEET_ID }
    ]
  })

  const { requestMutation: removeDevices } = useMutationWithHandlers({
    onCompletedCallback: () => onSubmit({ isOpen: false }),
    onErrorCallback: () => onSubmit({ isOpen: false }),
    queryFn: fleetMutationSVC.removeDevice,
    refetchQueries: [
      { id: fleetId, key: fleetSVCKeys.GET_DESCRETE_ASSETS_BY_FLEET_ID },
      { id: fleetId, key: fleetSVCKeys.FLEET_VIEW_GROUP_BY },
      { id: fleetId, key: fleetSVCKeys.GET_STATISTICS_BY_FLEET_ID }
    ]
  })

  const onDeleteMutationComplete = () => {
    onSubmit({ isOpen: false })
  }

  const { requestMutation: deleteFleet } = useMutationWithHandlers({
    onCompletedCallback: onDeleteMutationComplete,
    onErrorCallback: () => onSubmit({ isOpen: false }),
    queryFn: fleetMutationSVC.deleteFleetByFleetId,
    refetchQueries: [
      { id: fleetId, key: fleetSVCKeys.GET_DESCRETE_ASSETS_BY_FLEET_ID },
      { id: fleetId, key: fleetSVCKeys.FLEET_VIEW_GROUP_BY }
    ]
  })

  const handleOnSubmit = () => {
    let assetCountMessage = selectedDevice[0]?.name
    if (selectedDevice?.length > 1) {
      assetCountMessage = "selected assets"
    }
    switch (type) {
    case "addDevice":
      addDevices({
        errorMessage: i18next.t("asset:moveAssetsErrorMessage"),
        payload: {
          ...getAddRemoveAssetsPayload(
            createDeviceList(selectedDevice),
            selectedFleet.subFleet
              ? selectedFleet.subFleet
              : selectedFleet.fleetId
          )
        },
        successMessage: `Successfully moved ${assetCountMessage} to ${
          selectedFleet.subFleetName || selectedFleet.fleetName
        }.`
      })
      break
    case "removeDevice":
      removeDevices({
        errorMessage: i18next.t("asset:removeAssetsErrorMessage"),
        payload: {
          ...getRemoveAssetsPayload(createDeviceList(selectedDevice))
        },
        successMessage: `Successfully moved ${assetCountMessage} to ${
          selectedFleet.subFleetName || selectedFleet.fleetName
        }.`
      })
      break
    case "delete":
      deleteFleet({
        errorMessage: `Failed to delete ${deleteFleetDetails.name}`,
        payload: getDeleteFleetPayload(deleteFleetDetails.id),
        successMessage: `${deleteFleetDetails.name} is deleted.`
      })
      break
      /* istanbul ignore next */
    default:
      onSubmit({ isOpen: false })
    }
  }

  return (
    <ConfirmationPopup
      heading={ heading }
      isOpen={ isOpen }
      onSubmit={ handleOnSubmit }
      onCancel={ onCancel }
      confirm={ OK }
      cancel={ type && CANCEL }
      popupStyles={ popupStyles }
    >
      <span className={ styles.confirmContent }>{ message }</span>

      { warning && (
        <span className={ styles.warningSection }>
          <Icon icon={ ICON_NAME.alertRed } iconStyles={ styles.warningIcon } />
          <span className={ styles.confirmText }>{ warning }</span>
        </span>
      ) }
    </ConfirmationPopup>
  )
}
ConfirmMoveSubFleet.propTypes = {
  deleteFleetDetails: PropTypes.object,
  fleetId: PropTypes.string,
  heading: PropTypes.string,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  onCancel: PropTypes.any,
  onSubmit: PropTypes.any,
  popupStyles: PropTypes.any,
  selectedDevice: PropTypes.array,
  selectedFleet: PropTypes.object,
  type: PropTypes.string,
  warning: PropTypes.string
}
export default ConfirmMoveSubFleet
