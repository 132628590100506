/* eslint-disable react-hooks/exhaustive-deps */
import i18next from "i18next"
import PropTypes from "prop-types"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { BREADCRUMB_ROUTE } from "../../components/Breadcrumb/BreadcrumbUtils"
import GroupByAssetsFleetColumnsData from "../../pages/SiteView/FleetsSummary/internals/GroupByAssetsFleetColumnsData"
import {
  SITE_ACTION_TYPES,
  SITE_VIEW_STATE,
  getAddRemoveAssetsPayload,
  siteViewReducer,
  transformFilterData,
  transformSiteInformation,
  transformSiteStatistics
} from "../../pages/SiteView/SiteUtils"
import {
  fleetGetSVC,
  fleetMutationSVC,
  fleetSVCKeys
} from "../../services/reactQueries/fleetsvc"
import { QUERY_KEYS } from "../../utils/Constants/Queries"
import {
  useLazyQuery,
  useMutationWithHandlers,
  useQuery
} from "../../utils/CustomHooks/reactQuery"
import { ROUTE_CONFIG } from "../../utils/GlobalConstants"
import { useBreadcrumbContext } from "../breadcrumb/breadcrumb"

const SiteView = React.createContext({})

export const SiteViewProvider = ({ children }) => {
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState({
    isOpen: false,
    name: "",
    type: { action: "", assets: [], id: "", type: "" }
  })
  const { updateBreadcrumbData } = useBreadcrumbContext()
  const queryParams = useParams()
  const siteId = queryParams[ROUTE_CONFIG.SITE.param]
  const assetsQueryKey = [QUERY_KEYS.SITE_VIEW_ASSETS, siteId]
  const groupBySiteQueryKey = [fleetSVCKeys.SITE_VIEW_GROUP_BY, siteId]
  const fleetListCardRef = useRef(null)
  const assetContainerRef = useRef(null)
  const fleetSummaryRef = useRef(null)
  const ṣearchInputRef = useRef(null)

  const { t } = useTranslation(["site"])
  const [state, dispatch] = useReducer(siteViewReducer, SITE_VIEW_STATE)
  const groupByAssetsColumnsData = GroupByAssetsFleetColumnsData()

  const setGrouByAssetsData = (assets, reset) => {
    const list = state.currPage === 1 ? assets : [...state.grouByAssetsData, ...assets]
    dispatch({
      payload: reset ? [] : list,
      type: SITE_ACTION_TYPES.SET_GROUP_BY_ASSETS
    })
  }

  const onDataLoadCompleted = (data) => {
    if (
      state.searchInput &&
      state.groupByMappingObject.serviceName === "assets"
    ) {
      if (data?.data?.[state.groupByMappingObject.responseKey]?.length === 1 && state.currPage <= 1) {
        const asset = data?.data?.[state.groupByMappingObject.responseKey]?.[0]
        updateBreadcrumbData({
          id: asset?.id,
          key: BREADCRUMB_ROUTE.ASSET,
          name: asset?.friendlyName
        })
        const path = window.location.pathname.concat(
          `/${ROUTE_CONFIG.ASSET.routeName}/${asset?.id}`
        )
        navigate(path)
      } else {
        const assetsData = data?.data?.[state.groupByMappingObject.responseKey]
        setGrouByAssetsData(assetsData)
        dispatch({
          payload: data?.data,
          type: SITE_ACTION_TYPES.SEARCH_RESULTS
        })
      }
    } else if (
      state.filterValue?.length &&
      state.groupByMappingObject.serviceName === "assets"
    ) {
      const assetsData = data?.data?.[state.groupByMappingObject.responseKey]
      setGrouByAssetsData(assetsData)
      dispatch({
        payload: data?.data?.[state.groupByMappingObject.responseKey],
        type: SITE_ACTION_TYPES.FILTER_RESULT
      })
    } else if (state.groupByMappingObject.serviceName === "fleets") {
      if (data?.data?.[state.groupByMappingObject.responseKey]?.length === 0)
        toggleAccordionExpand(true)
    } else if (state.groupByMappingObject.serviceName === "assets") {
      const assetsData = data?.data?.[state.groupByMappingObject.responseKey]
      setGrouByAssetsData(assetsData)
    } else {
      toggleAccordionExpand(false)
    }
  }

  const [
    getGroupBySiteData,
    {
      isError,
      data: groupByData,
      error: apiError,
      isFetched: fetchFleetData,
      isFetching: fetchingNewFleets
    }
  ] = useLazyQuery(
    groupBySiteQueryKey,
    () =>
      fleetGetSVC.getSiteGroupBy(
        siteId,
        state.groupByMappingObject.serviceName,
        state.searchInput,
        state.filterValue,
        state.currPage
      ),
    { onSuccess: onDataLoadCompleted }
  )

  const allGroupByCommonData =
    groupByData?.data?.[state.groupByMappingObject.responseKey]

  useEffect(() => {
    if (state.groupByMappingObject && !state.searchError) getGroupBySiteData()
  }, [
    siteId,
    state.groupBySelectedValue,
    state.searchInput,
    state.searchError,
    state.filterValue,
    state.currPage
  ])

  const {
    data: deviceData,
    isFetched: fetchDeviceData,
    isError: isAssetError
  } = useQuery([fleetSVCKeys.GET_DESCRETE_ASSETS_BY_SITE_ID, siteId], () =>
    fleetGetSVC.getDescreteAssetsBySiteId(siteId)
  )

  const siteViewFilterData = transformFilterData()

  const isLoading = !(fetchFleetData && fetchDeviceData)
  const assetsNotInFleetData =
    fetchFleetData && groupByData?.data?.discreteAssets
  const assets = fetchDeviceData && deviceData?.data?.assets
  const groupByTypeDataAvailable =
    fetchFleetData && groupByData?.data?.data?.groupBy

  const { data: siteStatistics } = useQuery(
    [fleetSVCKeys.GET_STATISTICS_BY_SITE_ID, siteId],
    () => fleetGetSVC.getSiteStatistics(siteId)
  )
  const siteStatisticsData = siteStatistics?.data?.statistics

  const { distributionData } = useCallback(
    transformSiteStatistics(siteStatisticsData),
    [siteStatistics?.data]
  )
  const { assetsNotInFleet, contents } = useCallback(
    transformSiteInformation(
      allGroupByCommonData,
      assets,
      assetsNotInFleetData,
      state.searchInput,
      fetchingNewFleets,
      siteStatisticsData
    ),
    [allGroupByCommonData, assets]
  )

  const resetDrag = () => {
    dispatch({ payload: {}, type: SITE_ACTION_TYPES.DRAG_DETAILS })
    fleetSummaryRef?.current?.resetCheckboxSelection()
  }

  const { requestMutation: addDeviceToFleet, ...addDeviceMutationResults } =
    useMutationWithHandlers({
      onCompletedCallback: resetDrag,
      onErrorCallback: resetDrag,
      queryFn: fleetMutationSVC.addAssets,
      refetchQueries: [
        { id: siteId, key: fleetSVCKeys.GET_DESCRETE_ASSETS_BY_SITE_ID },
        { id: siteId, key: fleetSVCKeys.FLEET_VIEW_GROUP_BY_PAGES },
        { id: siteId, key: fleetSVCKeys.SITE_VIEW_GROUP_BY }
      ]
    })

  const handleOpenMoveFleet = (payload) => {
    dispatch({ payload, type: SITE_ACTION_TYPES.OPEN_MOVE_FLEET_POPUP })
  }

  const handleConfirmPopup = (payload) => {
    dispatch({ payload, type: SITE_ACTION_TYPES.CONFIRM_POPUP })
  }

  const toggleView = () => {
    dispatch({ type: SITE_ACTION_TYPES.TOGGLE_VIEW })
  }

  const toggleAccordionExpand = (payload) => {
    dispatch({ payload, type: SITE_ACTION_TYPES.TOGGLE_ACCORDION_EXPAND })
  }

  const setSelectedAssets = (payload) => {
    dispatch({ payload, type: SITE_ACTION_TYPES.SET_SELECTED_ASSETS })
  }

  const setGroupBySelectedValue = (payload) => {
    setGrouByAssetsData([], true)
    dispatch({ payload, type: SITE_ACTION_TYPES.SET_GROUP_BY_DROPDOWN })
  }

  const setGroupByChangeMethod = (payload) => {
    dispatch({ payload, type: SITE_ACTION_TYPES.SET_GROUP_BY_CHANGE_METHOD })
  }

  const getFilterAssets = async (value) => {
    dispatch({ payload: value, type: SITE_ACTION_TYPES.FILTER_INPUT })
    fleetSummaryRef?.current?.resetCheckboxSelection()
    handleSearchError("", false)
  }

  const handleSearchAssets = (value, closeAction) => {
    dispatch({
      payload: { closeAction, value },
      type: SITE_ACTION_TYPES.SEARCH_INPUT
    })
  }

  const handleSearchError = (value, hasError) => {
    dispatch({
      payload: { hasError, value },
      type: SITE_ACTION_TYPES.SEARCH_ERROR
    })
  }

  const setShowNewFleetSection = (payload) => {
    dispatch({ payload, type: SITE_ACTION_TYPES.SET_NEW_FLEET_SELECTION })
  }

  const handleOnDragEnd = (result) => {
    if (
      result.draggableId &&
      result.destination &&
      result.destination.droppableId
    ) {
      const {
        draggableId,
        destination: { droppableId }
      } = result
      let droppedFleetDetails = contents.find(
        (eachFleet) => eachFleet.fleetId === droppableId
      )
      if (droppedFleetDetails) {
        droppedFleetDetails.deviceId = draggableId
      }

      let draggedAssetDetails = assetsNotInFleet?.assets?.find(
        (eachAsset) => eachAsset.id === draggableId
      )
      handleAddAssets(
        draggedAssetDetails,
        droppedFleetDetails,
        draggableId,
        droppableId
      )
    }
  }

  const handleAddAssets = (draggedAsset, droppedFleet, assetId, fleetId) => {
    const successMessage = `${draggedAsset.name} asset moved to ${droppedFleet.fleetName}`
    addDeviceToFleet({
      errorMessage: i18next.t("asset:moveAssetsErrorMessage"),
      payload: getAddRemoveAssetsPayload([assetId], fleetId),
      successMessage
    })
  }

  const disableToggle = useMemo(() => {
    let disableToggle = false

    if (state.groupBySelectedValue == 1) {
      if (
        assetsNotInFleet?.assets?.length === 0 ||
        state.searchError ||
        !state.isAssetsNotInFleetExpanded
      ) {
        disableToggle = true
      }
    } else if (state?.groupBySelectedValue === 7) {
      if (contents?.length == 0 || state.searchError) {
        disableToggle = true
      }
    } else {
      disableToggle = true
    }
    return disableToggle
  }, [
    state.searchError,
    state.searchInput,
    state.groupBySelectedValue,
    assetsNotInFleet?.assets,
    contents,
    state.isAssetsNotInFleetExpanded
  ])

  const SiteViewValue = useMemo(() => ({
    addDeviceMutationResults,
    assetContainerRef,
    disableToggle,
    fleetListCardRef,
    fleetSummaryRef,
    getFilterAssets,
    handleConfirmPopup,
    handleOnDragEnd,
    handleOpenMoveFleet,
    handleSearchAssets,
    isEdit,
    setGroupByChangeMethod,
    setGroupBySelectedValue,
    setIsEdit,
    setSearchError: handleSearchError,
    setSelectedAssets,
    setShowNewFleetSection,
    siteId,
    siteTranslation: t,
    siteViewState: {
      ...state,
      ...{
        apiError,
        assetsNotInFleet,
        assetsQueryKey,
        contents,
        distributionData,
        groupByAssetsColumnsData,
        groupBySiteQueryKey,
        groupByTypeDataAvailable,
        isAssetError,
        isError,
        isLoading,
        paginationData: groupByData?.data?.pagination,
        siteViewFilterData
      }
    },
    toggleAccordionExpand,
    toggleView,
    ṣearchInputRef
  }))

  return (
    <SiteView.Provider value={ SiteViewValue }>{ children }</SiteView.Provider>
  )
}

SiteViewProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useSiteViewContext = () => {
  return useContext(SiteView)
}
