import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React, { useId, useState } from "react"

import style from "./MoreMenu.module.scss"

import AuthGuard from "../../../../../components/AuthGuard/AuthGuard"
import Icon from "../../../../../components/Icon/Icon"
import {
  CONFIG_CARD_MENU_DATA,
  FLEET_CARD_MENU,
  FLEET_CARD_MENU_ORIGIN,
  ICON_NAME
} from "../../../../../utils/GlobalConstants"
import { classNames } from "../../../../../utils/styles/helper"

// eslint-disable-next-line no-unused-vars
const MoreMenu = ({ selected, setSelected, onClick, fileId, muted, row }) => {
  const buttonId = useId()
  const menuId = useId()
  const [menu, setMenu] = useState(null)
  const open = Boolean(menu)

  const handleClick = (event) => {
    event.preventDefault()
    setMenu(event.currentTarget)
    setSelected?.(true)
  }
  const handleClose = (event, name) => {
    event.preventDefault()
    handleMenuEvent(name)
    setMenu(null)
    setSelected?.(false)
  }

  const handleMenuEvent = (name) => {
    if (name === "Restore" && !muted) {
      onClick?.(name)
    } else if (name === "Delete" && !row.isDeploymentInProgress) {
      onClick?.(name.toLowerCase())
    }
  }

  const getCssStyle = (id, isMuted, inprogressState) => {
    if (id !== 3) {
      if (isMuted) {
        return style.disabledMenu
      }
    } else if (inprogressState) {
      return style.disabledMenu
    } else {
      return "FleetmenuItem"
    }
  }

  return (
    <span>
      <IconButton
        id={ buttonId }
        aria-controls={ open ? menuId : undefined }
        aria-haspopup="true"
        aria-expanded={ open ? "true" : undefined }
        onClick={ handleClick }
        data-testid={ `moreIcon-${row?.id}` }
        className={ classNames(style.fleetButton, selected && style.active) }
      >
        <Icon icon={ ICON_NAME.moreVertical } />
      </IconButton>
      <Menu
        id={ menuId }
        anchorEl={ menu }
        open={ open }
        onClose={ (event) => handleClose(event) }
        MenuListProps={ {
          "aria-labelledby": buttonId
        } }
        transformOrigin={ FLEET_CARD_MENU }
        className="fleetMenuBorder"
        anchorOrigin={ FLEET_CARD_MENU_ORIGIN }
      >
        { CONFIG_CARD_MENU_DATA.map((menuItem) => (
          <AuthGuard key={ menuItem.id } actionType={ menuItem.accessKey }>
            <Tooltip
              arrow
              title={
                Boolean(menuItem.id !== 1 && row?.isDeploymentInProgress) &&
              "Deployment in Progress"
              }
            >
              <MenuItem
                onClick={ (event) => handleClose(event, menuItem.name) }
                className={ getCssStyle(
                  menuItem?.id,
                  muted,
                  row?.isDeploymentInProgress
                ) }
              >
                <Icon icon={ menuItem.icon } iconStyles={ style.icon } />
                { menuItem.name }
              </MenuItem>
            </Tooltip>
          </AuthGuard>
        )) }
      </Menu>
    </span>
  )
}

MoreMenu.propTypes = {
  fileId: PropTypes.string,
  muted: PropTypes.bool,
  onClick: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  setSelected: PropTypes.func
}

export default MoreMenu
