import { addDays, subDays } from "date-fns"
import i18n from "i18next"

import { TABLE_ACTION } from "../../../../utils/Constants/AssetsConfig"
import { EMPTY_CELL_DEFAULT_VALUE } from "../../../../utils/Constants/DataTable"

const todaysDate = new Date()

export const DEFAULT_DATE_RANGE = {
  endDate: addDays(todaysDate, 0),
  maxDate: addDays(todaysDate, 0),
  minDate: subDays(todaysDate, 364),
  startDate: subDays(todaysDate, 29)
}

export const RETRY_BUTTON_STATUSES = [
  "rejected",
  "cancelled",
  "canceled",
  "failed"
]

export const INPROGRESS_STATUSES = [
  "in install",
  "ininstall",
  "in-install",
  "in download",
  "indownload",
  "in-download"
]

export const getHasAnyActionAvailable = (items) => {
  const hasSomeRetryActions = items?.some(item => 
    RETRY_BUTTON_STATUSES.includes(item?.status?.toLowerCase()) && !item?.isRemoved
  )
  const hasSomeCancelActions = items?.some(item => 
    INPROGRESS_STATUSES.includes(item?.status?.toLowerCase())
  )
  return hasSomeRetryActions || hasSomeCancelActions
}

const getConfirmMessages = (action, payload) => {
  const key = action === TABLE_ACTION.RETRY_CENTRAL_DEPLOYMENT ? "retry" : "cancel"
  const i18nKey = "configuration:centralConfigurations.deploymentActivities.confirmPopup"
  const heading = i18n.t(`${i18nKey}.${key}.heading`)
  let message = ""
  if (payload?.length > 1) {
    message = i18n.t(`${i18nKey}.${key}.multipleAssetsMessage`, {
      count: payload?.length
    })
  } else {
    message = i18n.t(`${i18nKey}.${key}.singleAssetsMessage`, {
      assetName: payload[0]?.assetName ?? payload?.assetName
    })
  }
  return { heading, message }
}

export const getRetryConfirmOverlayParams = (action, payload, isHeaderAction) => {
  const { message, heading } = getConfirmMessages(action, payload)

  return {
    fileName: payload?.[0]?.fileName,
    fromCentral: true,
    heading,
    isHeaderAction,
    isOpen: true,
    isRetry : true,
    jobId: payload?.[0]?.jobId,
    message,
    selectedAssets: payload,
    selectedFileId: payload?.[0]?.fileId,
    type: action
  }
}


export const getCancelConfirmOverlayParams = (action, payload, isHeaderAction) => {
  const { message, heading } = getConfirmMessages(action, payload)

  return {
    fileName: payload?.[0]?.fileName,
    fromCentral: true,
    heading,
    isHeaderAction,
    isOpen: true,
    message,
    selectedAssets: payload,
    selectedFileId: payload?.[0]?.jobId,
    type: action
  }
}

export const assignedJobandFileIdToAssets = (assets, activityData) => {
  assets?.forEach(asset => {
    asset["id"] = "" + asset.serialNumber + activityData?.jobId + activityData?.activity?.fileId
    asset["jobId"] = activityData?.jobId
    asset["fileId"] = activityData?.activity?.fileId
    asset["isRemoved"] = activityData?.activity?.isRemoved
    asset["fileName"] = activityData?.activity?.fileName
    asset["subFleet"] = asset.subFleet || EMPTY_CELL_DEFAULT_VALUE
    asset["status"] = asset?.status?.toLowerCase()
  })
  return assets
}

export const transformActivityAssetsData = (data, deploymentActivities, expandedJobId, hasAnyActionAccess) => {
  let fleetsData = data?.data?.fleets ? [...data.data.fleets] : []
  let discreteAssets = data?.data?.discreteAssets ? [...data.data.discreteAssets] : []

  if (fleetsData?.length > 0) fleetsData = fleetsData.filter(fleet => fleet?.assetDetails?.length > 0)
  if (discreteAssets?.length > 0) {
    fleetsData.push({
      assetDetails: discreteAssets,
      fleetName: i18n.t("configuration:centralConfigurations.deploymentActivities.labels.assetsNotInFleet")
    })
  }
    
  const deploymentActivitiesData = [...deploymentActivities]
  const idx = deploymentActivitiesData?.findIndex(item => item.jobId === expandedJobId)
  if (idx > -1) {
    fleetsData.forEach(fleet => {
      fleet.assetDetails = assignedJobandFileIdToAssets(fleet?.assetDetails, deploymentActivitiesData[idx])
      fleet["hasAnyActionAccess"] = getHasAnyActionAvailable(fleet?.assetDetails) && hasAnyActionAccess
    })
    deploymentActivitiesData[idx].fleets = fleetsData
  }
  return deploymentActivitiesData
}

export const resetAccordionExpandedState = (data, id) => {
  const newIdx = data.findIndex(item => item?.jobId === id)
  const oldIdx = data.findIndex(item => item?.isExpanded)
  if (oldIdx > -1) data[oldIdx].isExpanded = false
  if (newIdx > -1) data[newIdx].isExpanded = true
  return data
}
