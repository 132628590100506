import i18n from "i18next"

export const SEARCH_BY_TYPES = {
  ASSET: "asset",
  CONFIG: "config"
}

export const SEARCH_INSTANCES = {
  assetFaults: "assetFaults",
  assetProperties: "assetProperties",
  assetStatus: "assetStatus",
  centralConfig: "centralConfig",
  centralConfigDeployActivities: "centralConfigDeployActivities",
  centralConfigDeployment: "centralConfigDeployment",
  centralConfiguration: "centralConfiguration",
  centralSoftware: "centralSoftware",
  centralSoftwareDeployment: "centralSoftwareDeployment",
  customerView: "customerView",
  fleetView: "fleetView",
  manageAccess: "manageAccess",
  reportsConfiguration: "reportsConfiguration",
  reportsSWPackage: "reportsSWPackage",
  siteFaults: "siteFaults",
  siteNavigatorSearch: "siteNavigatorSearch",
  siteView: "siteView",
  subFleet: "subFleet",
  subFleetView: "subFleetView",
  userActivity: "userActivity",
  userManagement: "userManagement",
  userManagementGroup:"userManagementGroup",
  userManagementUser:"userManagementUser"
}

export const SEARCH_INFO = () => ({
  assetFaults: {
    info: i18n.t("search:searchInfo.assetFaults"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.assetFaults") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.assetFaults")
  },
  assetProperties: {
    info: i18n.t("search:searchInfo.assetProperties"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.assetProperties") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.assetProperties")
  },
  assetStatus: {
    info: i18n.t("search:searchInfo.assetStatus"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.assetStatus") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.assetMetrics")
  },
  centralConfig: {
    info: i18n.t("search:searchInfo.centralConfig"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.centralConfig") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.centralConfig")
  }, 
  centralConfigDeployActivities: {
    info: i18n.t("search:searchInfo.centralConfigDeployActivities"),
    searchByItems: [
      { key: SEARCH_BY_TYPES.ASSET, label: i18n.t("search:searchByItem.centralAssetDeployActivities") },
      { key: SEARCH_BY_TYPES.CONFIG, label: i18n.t("search:searchByItem.centralConfigDeployActivities") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.centralConfigDeployActivities")
  },
  centralConfigDeployment: {
    info: i18n.t("search:searchInfo.centralConfigDeployment"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.centralConfigDeployment") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.centralConfigDeployment")
  },
  centralConfiguration: {
    info: i18n.t("search:searchInfo.centralConfiguration"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.centralConfiguration") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.centralConfig")
  },
  centralSoftware: {
    info: i18n.t("search:searchInfo.centralSoftware"),
    tooltipTitle: i18n.t("search:tooltipTitle.centralSoftware")
  },
  centralSoftwareDeployment: {
    info: i18n.t("search:searchInfo.centralSWDeployment"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.centralSWDeployment") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.centralSWDeployment")
  },
  customerView: {
    info: i18n.t("search:searchInfo.customerView"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.customerView") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.customerView")
  },
  default: {
    info: i18n.t("search:searchInfo.default")
  },
  fleetView: {
    info: i18n.t("search:searchInfo.fleetView"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.fleetView") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.fleetView")
  },
  manageAccess: {
    info: i18n.t("search:searchInfo.manageAccess")
  },
  reportsConfiguration: {
    info: i18n.t("search:searchInfo.reportsConfiguration"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.reportsConfiguration") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.reportsConfiguration")
  },
  reportsSWPackage: {
    info: i18n.t("search:searchInfo.reportsSWPackage"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.reportsSWPackage") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.reportsSWPackage")
  },
  siteNavigatorSearch: {
    info: i18n.t("search:searchInfo.siteNavigator")
  },
  siteView: {
    info: i18n.t("search:searchInfo.siteView"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.siteView") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.siteView")
  },
  subFleet: {
    info: i18n.t("search:searchInfo.subFleet"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.subFleet") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.subFleetView")
  },
  subFleetView: {
    info: i18n.t("search:searchInfo.subFleetView")
  },
  userActivity: {
    info: i18n.t("search:searchInfo.userActivity")
  },
  userManagement: {
    info: i18n.t("search:searchInfo.userManagement"),
    searchByItems: [
      { label: i18n.t("search:searchByItem.userManagement") }
    ],
    tooltipTitle: i18n.t("search:tooltipTitle.userManagement")
  }
})
