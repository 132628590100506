import i18next from "i18next"
import PropTypes from "prop-types"
import React from "react"
import { useParams } from "react-router-dom"

import styles from "./ConfirmMoveFleet.module.scss"

import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup"
import {
  getAddRemoveAssetsPayload,
  getDeleteFleetPayload,
  getRemoveAssetsPayload
} from "../../pages/SiteView/SiteUtils"
import { fleetMutationSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import { CANCEL, ICON_NAME, OK, ROUTE_CONFIG } from "../../utils/GlobalConstants"
import Icon from "../Icon/Icon"

const ConfirmMoveFleet = ({
  deleteFleetDetails,
  isOpen,
  onSubmit,
  popupStyles,
  heading,
  onCancel,
  selectedDevice,
  selectedFleet,
  warning,
  message,
  type
}) => {
  const queryParams = useParams()
  const siteId = queryParams[ROUTE_CONFIG.SITE.param]
  const createDeviceList = (device) => device.map(({ serialNumber }) => serialNumber)

  const onDeleteMutationCompleted = () => {
    onSubmit({ isOpen: false })
  }

  const { requestMutation: deleteFleet } = useMutationWithHandlers({
    onCompletedCallback: onDeleteMutationCompleted,
    onErrorCallback: () => onSubmit({ isOpen: false }),
    queryFn: fleetMutationSVC.deleteFleetByFleetId,
    refetchQueries: [{ id: siteId, key: fleetSVCKeys.SITE_VIEW_GROUP_BY }]
  })

  const { requestMutation: addDevices } = useMutationWithHandlers({
    onCompletedCallback: () => onSubmit({ isOpen: false }),
    onErrorCallback: () => onSubmit({ isOpen: false }),
    queryFn: fleetMutationSVC.addAssets,
    refetchQueries: [
      { id: siteId, key: fleetSVCKeys.GET_DESCRETE_ASSETS_BY_SITE_ID },
      { id: siteId, key: fleetSVCKeys.SITE_VIEW_GROUP_BY }
    ]
  })

  const { requestMutation: removeDevices } = useMutationWithHandlers({
    onCompletedCallback: () => onSubmit({ isOpen: false }),
    onErrorCallback: () => onSubmit({ isOpen: false }),
    queryFn: fleetMutationSVC.removeDevice,
    refetchQueries: [
      { id: siteId, key: fleetSVCKeys.GET_DESCRETE_ASSETS_BY_SITE_ID },
      { id: siteId, key: fleetSVCKeys.SITE_VIEW_GROUP_BY }
    ]
  })

  const handleOnSubmit = () => {
    let assetCountMessage
    let removeAssetCountMessage
    switch (type) {
    case "addDevice":
      assetCountMessage = "asset " + selectedDevice[0]?.name
      if (selectedDevice?.length > 1) {
        assetCountMessage = "selected assets"
      }
      addDevices({
        errorMessage: i18next.t("asset:moveAssetsErrorMessage"),
        payload: {
          ...getAddRemoveAssetsPayload(
            createDeviceList(selectedDevice),
            selectedFleet.subFleet ? selectedFleet.subFleet : selectedFleet.fleetId
          )
        },
        successMessage: `Successfully moved ${assetCountMessage} to ${
          selectedFleet.subFleetName || selectedFleet.fleetName
        }.`
      })
      break
    case "delete":
      deleteFleet({
        errorMessage: `Failed to delete ${deleteFleetDetails.fleetName}`,
        payload: {
          ...getDeleteFleetPayload(deleteFleetDetails.fleetId)
        },
        successMessage: `${deleteFleetDetails.fleetName} is deleted.`
      })
      break
    case "removeDevice":
      removeAssetCountMessage = "asset " + selectedDevice[0]?.name
      if (selectedDevice?.length > 1) {
        removeAssetCountMessage = "selected assets"
      }
      removeDevices({
        errorMessage: i18next.t("asset:removeAssetsErrorMessage"),
        payload: {
          ...getRemoveAssetsPayload(
            createDeviceList(selectedDevice),
            selectedFleet.subFleet ? selectedFleet.subFleet : selectedFleet.fleetId
          )
        },
        successMessage: `Successfully moved ${removeAssetCountMessage} to ${
          selectedFleet.subFleetName || selectedFleet.fleetName
        }.`
      })
      break
      /* istanbul ignore next */
    default:
      onSubmit({ isOpen: false })
    }
  }

  return (
    <ConfirmationPopup
      heading={ heading }
      isOpen={ isOpen }
      onSubmit={ handleOnSubmit }
      onCancel={ onCancel }
      confirm={ OK }
      cancel={ type && CANCEL }
      popupStyles={ popupStyles }
    >
      <span className={ styles.confirmContent }>{ message }</span>

      { warning && (
        <span className={ styles.warningSection }>
          <Icon icon={ ICON_NAME.alertRed } iconStyles={ styles.warningIcon } />
          <span className={ styles.confirmText }>{ warning }</span>
        </span>
      ) }
    </ConfirmationPopup>
  )
}
ConfirmMoveFleet.propTypes = {
  deleteFleetDetails: PropTypes.object,
  heading: PropTypes.string,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  onCancel: PropTypes.any,
  onSubmit: PropTypes.any,
  popupStyles: PropTypes.any,
  selectedDevice: PropTypes.array,
  selectedFleet: PropTypes.object,
  type: PropTypes.string,
  warning: PropTypes.string
}
export default ConfirmMoveFleet
