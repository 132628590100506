import colors from "../../styles/jsExports/colorExport.module.scss"
import fonts from "../../styles/jsExports/fontExport.module.scss"
import margins from "../../styles/jsExports/marginExport.module.scss"

export const List = {
  styleOverrides: {
    root: {
      padding: 0
    }
  }
}

export const MenuItem = {
  styleOverrides: {
    root: {
      "& .MuiCheckbox-root": {
        marginRight: margins["marginInPixel-px10"],
        padding: 0
      },
      "&.DropdownBorder": {
        "&.Mui-selected": {
          borderRadius: `${margins["marginInPixel-px4"]}`
        }
      },
      "&.DropdownBorder.active": {
        "& .MuiListItemText-root .MuiListItemText-primary": {
          fontWeight: fonts["weight-bolder"]
        },
        backgroundColor: colors["dropdown-selectedBackground"],
        borderRadius: `${margins["marginInPixel-px4"]}`,
        color: colors["dropdown-selectedText"]
      },
      "&.DropdownBorder.active: hover": {
        "& img": {
          filter: "brightness(0) invert(1)"
        },
        "& img.BlackWhiteIcon": {
          filter: "invert(1) brightness(2)"
        },
        background: colors["dropdown-selectedBackground"],
        borderRadius: `${margins["marginInPixel-px4"]}`,
        color: colors["dropdown-selectedText"],
        fontWeight: fonts["weight-bolder"]
      },
      "&.Mui-focusVisible": {
        background: colors["dropdown-background"]
      },
      "&.Mui-selected": {
        "& .MuiListItemText-root": {
          "& .MuiListItemText-primary": {
            color: colors["dropdown-selectedText"],
            fontWeight: fonts["weight-bolder"]
          }
        },
        "&.Mui-focusVisible": {
          background: colors["dropdown-selectedBackground"]
        },
        backgroundColor: colors["dropdown-selectedBackground"]
      },
      "&.Mui-selected:hover": {
        backgroundColor: colors["dropdown-selectedBackground"]
      },
      "&.MultiSelectClearAll": {
        color: colors["dropdown-selectAllText"],
        fontWeight: fonts["weight-normal"],
        lineHeight: margins["marginInPixel-px24"]
      },
      "&.SearchMenuItem": {
        "&.Mui-disabled": {
          ":hover": {
            backgroundColor: "inherit"
          },
          pointerEvents: "unset"
        },
        borderRadius: 0,
        padding: `${margins["marginInPixel-px4"]} ${margins["marginInPixel-px12"]}`
      },
      "&.UserMenuItem": {
        backgroundColor: colors["dropdown-backgroundLightGray"]
      },
      "&.UserMenuItem.active": {
        backgroundColor: colors["dropdown-userProfileSelectedBackground"],
        borderRadius: `${margins["marginInPixel-px4"]}`,
        fontWeight: fonts["weight-bolder"]
      },
      "&.UserMenuItem: hover": {
        backgroundColor: colors["userSettingsMenuBackgroundHover"],
        borderRadius: `${margins["marginInPixel-px8"]}`
      },
      "&:not(.Mui-selected):hover": {
        backgroundColor: colors["dropdown-dropdownHover"],
        borderRadius: `${margins["marginInPixel-px4"]}`
      },
      // DO NOT ALTER THE SORT ORDER
      // eslint-disable-next-line sort-keys 
      "&.FleetmenuItem: hover": {
        "& img": {
          filter: "brightness(0) invert(1)"
        },
        "& img.BlackWhiteIcon": {
          filter: "invert(1) brightness(2)"
        },
        background: colors["dropdown-selectedBackground"],
        borderRadius: margins["margins-px4"],
        color: colors["dropdown-selectedText"],
        fontWeight: fonts["weight-bolder"]
      },
      backgroundColor: colors["dropdown-background"],
      color: colors["dropdown-inputText"]
    }
  }
}

export const Paper = {
  styleOverrides: {
    root: {
      borderRadius: `0 0 ${margins["marginInPixel-px1"]} ${margins["marginInPixel-px1"]}`,
      boxShadow: `0 ${margins["marginInPixel-px2"]} ${margins["marginInPixel-px4"]} rgba(0, 0, 0, 0.23)`
    }
  }
}

export const Select = {
  styleOverrides: {
    root: {
      "& .MuiSelect-icon": {
        right: margins["margins-px13"],
        top: margins["margins-px13"]
      },
      "& .MuiSelect-outlined": {
        "&.MuiInputBase-input": {
          color: colors["dropdown-inputText"],
          fontStyle: "normal",
          lineHeight: margins["marginInPixel-px22"]
        },
        // eslint-disable-next-line max-len
        padding: `${margins["marginInPixel-px6"]} ${margins["marginInPixel-px32"]} ${margins["marginInPixel-px6"]} ${margins["marginInPixel-px12"]}`
      },
      "&.DropdownSelect": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: colors["dropdown-border"]
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: colors["dropdown-border"],
          borderWidth: margins["marginInPixel-px2"]
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: colors["dropdown-border"]
        },
        borderRadius: `${margins["marginInPixel-px4"]}`
      },
      "&.Mui-disabled": {
        background: colors["dropdown-lightDisabledBackground"]
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: colors["dropdown-disabledBackground"]
      },
      "&.Mui-disabled .MuiSelect-icon": {
        opacity: 0.4
      },
      "&.Mui-disabled .MuiSelect-outlined": {
        "&.MuiInputBase-input": {
          opacity: 0.8
        }
      },
      "&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: colors["dropdown-border"],
        borderWidth: margins["marginInPixel-px2"]
      },
      "&.TimePickerDropdownSelect": {
        "& .MuiSelect-icon": { top: 10 }
      },
      background: colors["dropdown-background"],
      borderColor: colors["dropdown-border"],
      borderRadius: margins["marginInPixel-px1"],
      borderWidth: margins["marginInPixel-px1"],
      lineHeight: margins["marginInPixel-px22"]
    }
  }
}

export const FormLabel = {
  styleOverrides: {
    root: {
      "&.MuiFormLabel-colorPrimary": {
        color: colors["dropdown-labelText"],
        lineHeight: margins["marginInPixel-px22"],
        marginBottom: margins["marginInPixel-px8"]
      },
      "&.MuiFormLabel-colorPrimary.Mui-required .MuiFormLabel-asterisk": {
        color: colors["dropdown-required"]
      }
    }
  }
}

export const Menu = {
  styleOverrides: {
    root: {
      "& .MuiPaper-root": {
        background: colors["dropdown-backgroundGray"],
        borderRadius: `${margins["marginInPixel-px4"]}`,
        boxShadow: "0px 1px 5px 0px #1E1F2052",
        marginTop: margins["marginInPixel-px4"],
        padding: margins["marginInPixel-px4"]
      },
      "&.UserProfileMenu .MuiPaper-root": {
        background: colors["dropdown-backgroundLightGray"],
        borderRadius: `${margins["marginInPixel-px8"]}`,
        boxShadow: `0px 1px 5px 0px ${colors["dropdown-dropdownShadow"]}`,
        left: "auto !important",
        marginTop: 0,
        right: `${margins["marginInPixel-px8"]} !important`,
        top: `${margins["marginInPixel-px46"]} !important`
      },
      "&.fleetMenuBorder ": {
        "& .MuiPaper-root": {
          background: colors["dropdown-backgroundGray"],
          borderRadius: `${margins["marginInPixel-px8"]}`,
          marginTop: `${margins["marginInPixel-px4"]}`,
          minWidth: "calc(100% - 88%)",
          padding: `${margins["marginInPixel-px4"]}`
        }
      },
      "&.reportsDropDown ": {
        "& .MuiPaper-root": {
          left: "88px !important"
        }
      }
    }
  }
}
