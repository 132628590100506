import { Tab, Tabs } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./Reports.module.scss"
import HeadSection from "./Tabs/Common/HeadSection/HeadSection"
import Configuration from "./Tabs/Configuration/Configuration"
import SWPackage from "./Tabs/SWPackage/SWPackage"

import Error from "../../components/Error/Error"
import Loading from "../../components/Loading/Loading"
import TabPanel from "../../components/TabPanel/TabPanel"
import { useReportsContext } from "../../contexts/reports/reports"
import { ReportAuthElement } from "../../utils/Constants/Auth/report"
import useAccess from "../../utils/CustomHooks/useAccess"
import { classNames } from "../../utils/styles/helper"

const ReportsView = () => {
  const {
    isModalityListLoading,
    configurations,
    swPackage,
    modality,
    model,
    handleTabChange,
    sectionTab,
    modalityOptions,
    modelOptions,
    configApiFailure,
    getConfigReportFiles,
    siteUcmId,
    searchInput,
    resetSearch,
    reportsDateRange,
    handleSearch,
    searchResult,
    searchError,
    searchClose,
    configDataLoading,
    getSWPackageReportFiles,
    swPackageApiFailure,
    swPackageDataLoading,
    searchBarRef,
    setSortingObject,
    setExportData
  } = useReportsContext()
  const { t } = useTranslation(["reports"])

  const hasConfigAccess = useAccess(ReportAuthElement.ReadConfigReport)
  const hasSwAccess = useAccess(ReportAuthElement.ReadSWDistReport)

  const handleSortChange = (sortObject, sortedRows) => {
    if (sortObject) setSortingObject(sortObject)
    setExportData(sortedRows.flatRows.map((each) => each.original))
  }

  const allTabs = [
    {
      component: (
        <Configuration
          configurations={ configurations }
          modality={ modality }
          searchError={ searchError }
          searchResult={ searchResult }
          isLoading={ configDataLoading }
          handleSortChange={ handleSortChange }
        />
      ),
      exportAuthKey: ReportAuthElement.ExportConfigReport,
      hasAccess: hasConfigAccess,
      id: 1,
      label: t("tabs.configuration")
    },
    {
      component: (
        <SWPackage
          swPackage={ swPackage }
          modality={ modality }
          isLoading={ swPackageDataLoading }
          searchError={ searchError }
          searchResult={ searchResult }
          handleSortChange={ handleSortChange }
        />
      ),
      exportAuthKey: ReportAuthElement.ExportSWReport,
      hasAccess: hasSwAccess,
      id: 2,
      label: t("tabs.swPackage")
    }
  ]

  const tabs = allTabs.filter((tab) => tab.hasAccess)

  const getReportsData = () => {
    if (tabs[sectionTab]?.id === 1) {
      let configSearchInputData = searchInput
      searchBarRef.current?.reset()
      resetSearch()
      configSearchInputData = null
      getConfigReportFiles({
        payload: {
          modality: modality,
          modalityOptions,
          model: model,
          modelOptions,
          reportsDateRange: reportsDateRange,
          searchInput: configSearchInputData,
          siteId: siteUcmId
        },
        showToast: false
      })
    } else if (tabs[sectionTab]?.id === 2) {
      let swPackageSearchInputData = searchInput
      searchBarRef.current?.reset()
      resetSearch()
      swPackageSearchInputData = null
      getSWPackageReportFiles({
        payload: {
          modality: modality,
          modalityOptions,
          model: model,
          modelOptions,
          reportsDateRange: reportsDateRange,
          searchInput: swPackageSearchInputData,
          siteId: siteUcmId
        },
        showToast: false
      })
    }
  }

  if (isModalityListLoading) return <Loading />
  if (configApiFailure || swPackageApiFailure)
    return (
      <Error
        error={ {
          message: configApiFailure
            ? t("configurationTab.apiFaliureMessage")
            : t("swPackageTab.apiFaliureMessage")
        } }
      />
    )

  return (
    <>
      <div className={ styles.root }>
        <Tabs
          className={ classNames(styles.tabsContainer, "reportTabs") }
          value={ sectionTab }
          onChange={ handleTabChange }
        >
          { tabs.map((tab, index) => (
            <Tab key={ tab.id } label={ tab.label } { ...a11yProps(index) } />
          )) }
        </Tabs>
        <div className={ styles.headSectioon }>
          <p className={ styles.descText } aria-label="description">
            { t(
              `${
                tabs[sectionTab]?.id === 1 ? "configurationTab" : "swPackageTab"
              }.description`
            ) }
          </p>
          <HeadSection
            handleGetReports={ getReportsData }
            configurations={ configurations }
            swPackage={ swPackage }
            value={ sectionTab }
            exportAuthKey={ tabs[sectionTab]?.exportAuthKey }
            handleSearch={ handleSearch }
            searchInput={ searchInput }
            searchResult={ searchResult }
            searchError={ searchError }
            searchClose={ searchClose }
            searchBarRef={ searchBarRef }
            tabs={ tabs }
          />
        </div>
      </div>
      { tabs.map((tab, index) => (
        <TabPanel
          key={ tab.id }
          className={ styles.tabPanel }
          value={ sectionTab }
          index={ index }
        >
          { tab.component }
        </TabPanel>
      )) }
    </>
  )
}

export default ReportsView

const a11yProps = (index) => {
  return {
    "aria-controls": `tabpanel-${index}`,
    id: `tab-${index}`
  }
}
