import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import PropTypes from "prop-types"
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from "react"

import SearchAdornment from "./internal/SearchAdornment"
import SearchByItems from "./internal/SearchByItems"
import styles from "./SearchBar.module.scss"

import { SEARCH_INFO, SEARCH_INSTANCES } from "../../utils/Constants/Search"
import {
  COMPARE_VALUE,
  SEARCH_DEFAULT_MIN_CHARACTERS,
  specialCharactersNotAllowedForSearch
} from "../../utils/GlobalConstants"

// eslint-disable-next-line react/display-name
const SearchBar = forwardRef(
  (
    { type, inputStyles, onChange, onHandleSubmit, isDisabled, ...props },
    ref
  ) => {
    const menuItemRef = useRef(null)
    const [searchInput, setSearchInput] = useState(null)
    const [inputState, setInputState] = useState(null)

    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
      reset: () => {
        setSearchInput(null)
      },
      setValue: (value) => {
        const formattedValue = value.replace(specialCharactersNotAllowedForSearch, "")
        setSearchInput(formattedValue)
        onChange?.(formattedValue)
      }
    }))

    const handleOnChange = (e) => {
      const value = e.target.value.replace(specialCharactersNotAllowedForSearch, "")
      setSearchInput(value)
      onChange?.(value)
    }

    const setHandleSubmit = (value, closeAction, searchBy) => {
      const formatedValue = value.split(" ")
        .filter((e) => e.replaceAll(" ", ""))
        .join(" ")
      setSearchInput(closeAction ? null : formatedValue)
      if (closeAction) {
        onHandleSubmit?.(formatedValue, true, false, searchBy)
      } else if (
        formatedValue.length < (props.minCharacters || SEARCH_DEFAULT_MIN_CHARACTERS)
      ) {
        onHandleSubmit?.(formatedValue, false, true, searchBy)
      } else {
        onHandleSubmit?.(formatedValue, false, false, searchBy)
      }
      inputRef.current.blur()
    }

    const placeholder = searchInput === null ? SEARCH_INFO()["default"]?.info : ""
    const isSearchMenuDisabled = !searchInput || searchInput?.length < 3
    const searchByMenuItems = SEARCH_INFO()[type]?.searchByItems ?? []

    const handleSearchMenuClick = (e, searchBy) => {
      e.preventDefault()
      if (isSearchMenuDisabled) {
        inputRef?.current?.focus()
      } else {
        setInputState(null) 
        setHandleSubmit(searchInput ?? "", false, searchBy)
      }
    }

    return (
      <div className={ styles.searchContainer }>
        <FormControl variant="outlined">
          <OutlinedInput
            className={ "noError" }
            inputRef={ inputRef }
            onFocus={ () => setInputState("focus") }
            onBlur={ (e) => !menuItemRef?.current?.contains(e.relatedTarget) && setInputState(null) }
            data-testid="search-input"
            placeholder={ placeholder }
            sx={ { ...inputStyles } }
            onChange={ handleOnChange }
            onKeyDown={ (e) =>
              e.key === COMPARE_VALUE.enter && setHandleSubmit(e.target.value)
            }
            value={ searchInput || "" }
            disabled={ isDisabled }
            { ...props }
            endAdornment={
              <SearchAdornment
                showClearButton={ searchInput !== null || inputState === "focus" }
                isDisabled={ isDisabled }
                setHandleSubmit={ setHandleSubmit }
              />
            }
          />
        </FormControl>
        { searchByMenuItems?.length > 0 && inputState === "focus" &&
          <SearchByItems
            searchByMenuItems={ searchByMenuItems }
            isSearchMenuDisabled={ isSearchMenuDisabled }
            handleSearchMenuClick={ handleSearchMenuClick }
            ref={ menuItemRef }
          />
        }
      </div>
    )
  }
)

SearchBar.propTypes = {
  inputStyles: PropTypes.any,
  isDisabled: PropTypes.bool,
  minCharacters: PropTypes.number,
  onChange: PropTypes.func,
  onHandleSubmit: PropTypes.func,
  type: PropTypes.oneOf(Object.values(SEARCH_INSTANCES))
}

export default SearchBar
