import React from "react"

import Actions from "./internals/Actions"

import { DATE_FORMAT } from "../../../utils/Constants/TimeZones"
import { formatDate } from "../../../utils/helper"
import margins from "../../../utils/styles/jsExports/marginExport.module.scss"

const ConfigurationTableColumnConfig = (
  handleRowClickActions,
  muted,
  hasAnyActionAccess,
  hasSomePromotedFiles,
  hasSomeDemotedFiles,
  hasSomeLockedFiles,
  hasSomeUnLockedFiles
) => [
  {
    accessorKey: "configurationDate",
    cell: ({ cell }) => <>{ formatDate(cell.getValue(), DATE_FORMAT.dateTime) }</>,
    className: muted && "opacityForText",
    defaultCanSort: true,
    header: "Configuration date",
    sortDesc: true,
    width: margins["marginInPercentage-per15o1"]
  },
  {
    accessorKey: "configFile",
    className: muted && "opacityForText",
    header: "Name",
    width: margins["marginInPercentage-per9o8"]
  },
  {
    accessorKey: "user",
    className: muted && "opacityForText",
    header: "Config by",
    width: margins["marginInPercentage-per9o8"]
  },
  {
    accessorKey: "configType",
    className: muted && "opacityForText",
    header: "Config type",
    width: margins["marginInPercentage-per9o8"]
  },
  {
    accessorKey: "description",
    className: muted && "opacityForText",
    header: "Description",
    width: margins["marginInPercentage-per13"]
  },
  {
    accessorKey: "actions",
    cell: (tableProps) => (
      <Actions
        tableProps={ tableProps }
        handleRowClickActions={ handleRowClickActions }
        muted={ muted }
        hasSomePromotedFiles={ hasSomePromotedFiles }
        hasSomeDemotedFiles={ hasSomeDemotedFiles }
        hasSomeLockedFiles={ hasSomeLockedFiles }
        hasSomeUnLockedFiles={ hasSomeUnLockedFiles }
      />
    ),
    disableTooltip: true,
    enableResizing: false,
    enableSorting: false,
    header: "Action",
    minSize: 140,
    show: hasAnyActionAccess,
    size: 140,
    width: margins["marginInPercentage-per18o2"]
  }
]

export default ConfigurationTableColumnConfig
