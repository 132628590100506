/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery, useQueryClient } from "react-query"
import { useLocation } from "react-router"
import { Link, useNavigate, useParams } from "react-router-dom"

import styles from "./CentralSoftwareDeployment.module.scss"
import { transformAssets, transformFilterData } from "./CentralSoftwareDeploymentUtils"
import DeploymentAssetsSummary from "./DeploymentAssetsSummary/DeploymentAssetsSummary"

import { getAndUpdateAssetBreadcrumb } from "../../../components/Breadcrumb/BreadcrumbUtils"
import ConfigFilterPopup from "../../../components/ConfigFilterPopup/ConfigFilterPopup"
import Error from "../../../components/Error/Error"
import Icon from "../../../components/Icon/Icon"
import SWPackageDeployModal from "../../../components/SWPackageDeployModal/SWPackageDeployModal"
import { useBreadcrumbContext } from "../../../contexts/breadcrumb/breadcrumb"
import { SoftwareUpdateProvider } from "../../../contexts/swUpdate/SoftwareUpdateContext"
import { useToaster } from "../../../contexts/ToastContext"
import { fleetGetSVC, fleetSVCKeys } from "../../../services/reactQueries/fleetsvc"
import { softwareSVC, softwareSVCKeys } from "../../../services/reactQueries/softwaresvc"
import { DATE_FORMAT } from "../../../utils/Constants/TimeZones"
import { useLazyQuery } from "../../../utils/CustomHooks/reactQuery"
import { COMPARE_VALUE, CONFIG_CONTEXT_TYPE, ICON_NAME, ROUTE_CONFIG } from "../../../utils/GlobalConstants"
import { formatDate } from "../../../utils/helper"
import { classNames } from "../../../utils/styles/helper"
import { SOFTWARE_TYPE } from "../../AssetView/SoftwarePackages/SoftwarePackagesUtils"

const CentralSoftwareDeployment = () => {
  const { t } = useTranslation(["software", "configuration"])
  const { t: common } = useTranslation(["common"])
  const navigate = useNavigate()
  let { state } = useLocation()

  const queryClient = useQueryClient()
  const { updateBreadcrumbData } = useBreadcrumbContext()
  const assetSummaryRef = useRef(null)
  const [selectedAssets, setSelectedAssets] = useState([])
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(state?.filter)
  const [isConfirmDeployPopUp, setIsConfirmDeployPopUp] = useState({
    isOpen: false,
    packageDetails: { name: "" }
  })
  const [filterAPIError, setFilterAPIError] = useState(false)
  const { displayMediumErrorToast } = useToaster()

  const queryParamIds = useParams()
  const deploySoftwareId = queryParamIds[ROUTE_CONFIG.SOFTWARE_DEPLOYMENT.param]
  const assetId = queryParamIds[ROUTE_CONFIG.ASSET.param]
  const customerId = queryParamIds[ROUTE_CONFIG.CUSTOMER.param]
  const siteId = queryParamIds[ROUTE_CONFIG.SITE.param]
  const queryKey = [softwareSVCKeys.GET_SOFTWARE_DEPLOYMENT_ASSETS, deploySoftwareId]

  useEffect(() => {
    getAndUpdateAssetBreadcrumb(queryClient, assetId, updateBreadcrumbData)
  }, [assetId])

  const [getAssets, { data, isFetching }] = useLazyQuery(
    queryKey,
    () => softwareSVC.getSoftwareDeploymentAssets(siteId, state?.packageDetails, selectedFilters),
    {
      onError: (err) => displayMediumErrorToast(err?.data?.error?.message)
    }
  )

  useEffect(() => {
    getAssets()
  }, [selectedFilters])

  useEffect(() => {
    getAndUpdateAssetBreadcrumb(queryClient, assetId, updateBreadcrumbData)
    const modelFilterItem = selectedFilters?.filter((item) => item.type === COMPARE_VALUE.model)
    if (!modelFilterItem || modelFilterItem.length === 0) navigate(-1)
  }, [assetId, selectedFilters])

  const swPackageDetails = state?.packageDetails
  const assetsRaw = data?.data?.assets ?? []
  const assets = useMemo(() => transformAssets(assetsRaw), [assetsRaw])

  const { data: swVerions } = useQuery([fleetSVCKeys.GET_SW_VERSION_BY_SITE_AND_MODALITY], () =>
    fleetGetSVC.getSWVersionsBySiteAndModality(siteId, selectedFilters?.[0].label)
  )
  const swVerionsList = swVerions?.data?.softwareVersions

  const configFilterData = transformFilterData(swVerionsList)

  const handleDeployClick = () => {
    setIsConfirmDeployPopUp({
      isOpen: true,
      packageDetails: swPackageDetails
    })
  }

  const getSelectedConfigDate = (date) => {
    return date ? formatDate(new Date(date), DATE_FORMAT.dateTime) : ""
  }

  const handleCancelDeploy = (value) => {
    setIsConfirmDeployPopUp({ isOpen: value })
  }

  const handleSubmitDeploy = () => {
    assetSummaryRef?.current?.resetCheckboxSelection()
    setIsConfirmDeployPopUp({ isOpen: false })
  }

  const onSubmitFilters = (newFilters) => {
    assetSummaryRef?.current?.resetSearchData()
    assetSummaryRef?.current?.resetCheckboxSelection()
    setSelectedFilters(newFilters)
  }

  if (!isFetching && filterAPIError) return <Error error={ { message: common("errorInFilterOptionsApi") } } />

  return (
    <SoftwareUpdateProvider value={ { filter: state?.filter } }>
      <div className={ styles.topHeading }>
        <div className={ styles.softwareHeader }>
          <Link
            className={ styles.backButton }
            aria-label="Back button"
            to={ `/customers/${customerId}/sites/${siteId}/swupdate` }
            state={ {
              dateRange: state?.dateRange,
              filter: state?.filter
            } }
          >
            <Icon icon={ ICON_NAME.backBlack } />
          </Link>
          { swPackageDetails && (
            <>
              <span className={ classNames(styles.heading, styles.textEllipsis) }>{ swPackageDetails?.name }</span>
              <span className={ styles.headingContainer }>
                <span className={ styles.subHeading }>{ t("heading.version") }</span>
                <span className={ styles.subHeadingValue }>{ swPackageDetails?.version }</span>
              </span>
              <span className={ styles.headingContainer }>
                <span className={ styles.subHeading }>{ t("heading.uploadedOn") }</span>
                <span className={ styles.subHeadingValue }>
                  { getSelectedConfigDate(swPackageDetails?.uploadedOn) }
                </span>
              </span>
            </>
          ) }
        </div>
        <span className={ styles.deployButtonCont }>
          <Button
            variant="contained"
            color="primary"
            startIcon={
              <Icon
                iconStyles={ styles.deployIcon }
                icon={ selectedAssets.length > 0 ? ICON_NAME.deploySoftware : ICON_NAME.deploySoftwareDisabled }
              />
            }
            onClick={ handleDeployClick }
            disabled={ selectedAssets.length === 0 }
          >
            { t("buttons.deploy") }
          </Button>
        </span>
      </div>
      <div className={ styles.assetSummaryContainer }>
        <DeploymentAssetsSummary
          ref={ assetSummaryRef }
          assets={ isFetching ? [] : assets }
          selectedAssets={ selectedAssets }
          setSelectedAssets={ setSelectedAssets }
          setIsFilterOpen={ setIsFilterOpen }
          setSelectedFilters={ onSubmitFilters }
          stateCarryForward={ state }
          queryKey={ queryKey }
        />
        { isFilterOpen && (
          <ConfigFilterPopup
            isFullScreenOverlay
            isPopupOpen={ isFilterOpen }
            onCancel={ setIsFilterOpen }
            setIsFilterOpen={ setIsFilterOpen }
            data={ configFilterData }
            popupStyles={ styles.overlay }
            stateCarryForward={ state }
            type={ CONFIG_CONTEXT_TYPE.CENTRAL_SOFTWARE }
            customLoadingStyle={ styles.contentLoading }
            showToastError={ false }
            setFilterAPIError={ setFilterAPIError }
            onSubmit={ onSubmitFilters }
            isModelMandatory={ true }
          />
        ) }
        { isConfirmDeployPopUp?.isOpen && (
          <SWPackageDeployModal
            t={ t }
            pageType={ SOFTWARE_TYPE.CENTRAL_VIEW }
            onCancel={ handleCancelDeploy }
            onSubmit={ handleSubmitDeploy }
            assetSerialNumber={ selectedAssets?.map((asset) => asset?.serialNumber) }
            packageDetails={ isConfirmDeployPopUp.packageDetails }
            { ...isConfirmDeployPopUp }
          />
        ) }
      </div>
    </SoftwareUpdateProvider>
  )
}

export default CentralSoftwareDeployment
